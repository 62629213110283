import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SlidingPane from "react-sliding-pane";
import MaskedInput from "react-text-mask"
import createNumberMask from "text-mask-addons/dist/createNumberMask"
import Select from "react-select"
import Flatpickr from "react-flatpickr";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";

import CurrencyInput from "../../CurrencyInput/components/CurrencyInput";
import { offsetDateForBrowser, offsetDateForServer } from "../../../shared/timeZoneLogic";
import { parseDateTime, parseFloatFromString, showActivityOption, timeOnDate } from "./Tickets";

const axios = require("axios").default;

const createPromoCode = (csrfToken, team, confirm, promoCode, onSuccess) => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
  axios.post("/teams/" + team.id + "/promo_codes", promoCodeParams(promoCode, confirm))
    .then(({ data }) => {
      onSuccess();
    })
    .catch((error) => {
      toast.error(error.response.data.join(", "), {
        position: toast.POSITION.TOP_CENTER,
        draggable: false,
        closeOnClick: false,
        autoClose: 5000,
        hideProgressBar: true
      });
    });
};

const filterPassedTime = (time, startDate, startTime, endDate) => {
  var startMonth = startDate.getUTCMonth() + 1;
  var startDay   = startDate.getUTCDate();
  var startYear  = startDate.getUTCFullYear();

  var endMonth = endDate.getUTCMonth() + 1;
  var endDay   = endDate.getUTCDate();
  var endYear  = endDate.getUTCFullYear();

  var sameDate = (
    startMonth === endMonth
      && startDay === endDay
      && startYear === endYear
  );

  if(!sameDate){
    return true;
  }

  var timeOption = new Date(time);
  var copiedStartTime = new Date(startTime.getTime());

  timeOption.setFullYear(startYear, startMonth, startDay);
  copiedStartTime.setFullYear(startYear, startMonth, startDay);

  return timeOption > copiedStartTime;
};

const updatePromoCode = (csrfToken, team, promoCode, onSuccess) => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
  axios.patch("/teams/" + team.id + "/promo_codes/" + promoCode.id, promoCodeParams(promoCode, confirm))
    .then(({ data }) => {
      onSuccess();
    })
    .catch((error) => {
      toast.error(error.response.data.join(", "), {
        position: toast.POSITION.TOP_CENTER,
        draggable: false,
        closeOnClick: false,
        autoClose: 5000,
        hideProgressBar: true
      });
    });
};

const canSubmitPromoCodeForm = (promoCode) => {
  var amount;
  if(promoCode.amount_two_decimals){
    amount = parseFloatFromString(promoCode.amount_two_decimals);
  }

  var percentage;
  if(promoCode.percentage){
    percentage = parseFloatFromString(promoCode.percentage);
  }

  var startDate = parseDateTime(promoCode.start_date_midday);
  var startTime = timeOnDate(startDate, promoCode.start_time_with_wrong_date);
  var endDate   = parseDateTime(promoCode.end_date_midday);
  var endTime   = timeOnDate(endDate, promoCode.end_time_with_wrong_date);

  return (
    promoCode
      && promoCode.name
      && (promoCode.unlimited || promoCode.limit > 0)
      && ((
        typeof(amount) === "number" && amount >= 0
      ) || (
        typeof(percentage) === "number" && percentage >= 0 && percentage <= 100
      ))
      && ((
        promoCode.availability === "Date & Time"
        && startDate
        && startTime
        && endDate
        && endTime
        && (startDate <= endDate || datesAreOnSameDay(startDate, endDate))
        && startTime <= endTime
      ) || (
        promoCode.availability === "Based on show details"
        && promoCode.start_show_activity_id
        && promoCode.end_show_activity_id
      ))
  );
};

const promoCodeParams = (promoCode, confirm) => {
  var params = {
    promo_code: {
      calendar_event_id: confirm.id,
      name: promoCode.name,
      amount: (promoCode.amount_two_decimals ? parseFloatFromString(promoCode.amount_two_decimals) : null),
      percentage: (promoCode.percentage ? parseFloatFromString(promoCode.percentage) : null),
      limit: promoCode.limit,
      unlimited: promoCode ? promoCode.unlimited : false,
      reveal_hidden_tickets: promoCode.reveal_hidden_tickets,
      ticket_type_ids: (promoCode.ticket_type_ids ? promoCode.ticket_type_ids : []),
      applies_to_ticket_types: promoCode.applies_to_ticket_types,
      applies_to_add_ons: promoCode.applies_to_add_ons,
      availability: promoCode.availability,
      start_date_midday: offsetDateForServer(promoCode.start_date_midday),
      start_time_with_wrong_date: offsetDateForServer(promoCode.start_time_with_wrong_date),
      end_date_midday: offsetDateForServer(promoCode.end_date_midday),
      end_time_with_wrong_date: offsetDateForServer(promoCode.end_time_with_wrong_date),
      start_show_activity_id: promoCode.start_show_activity_id,
      end_show_activity_id: promoCode.end_show_activity_id,
      limit_to_one_ticket_per_order: promoCode.limit_to_one_ticket_per_order,
      limit_to_one_add_on_per_order: promoCode.limit_to_one_add_on_per_order
    }
  }

  return params;
};

const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "transparent",
    zIndex: "999"
  }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #e6e6e6"
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: "9999 !important" })
};

const PromoCodeForm = ({
  confirm,
  csrfToken,
  setIsOpen,
  isOpen,
  promoCodeToCopy,
  promoCodeToEdit,
  onClose,
  showActivities,
  team,
  ticketTypes
}) => {
  const [promoCode, setPromoCode] = useState({});

  useEffect(() => {
    if(isOpen) {
      if (promoCodeToEdit.id) {
        axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
        axios.get("/teams/" + team.id + "/promo_codes/" + promoCodeToEdit.id + "/edit")
          .then(({ data }) => {
            setPromoCode(Object.assign({}, data, {
              start_date_midday: offsetDateForBrowser(data.start_date_midday),
              start_time_with_wrong_date: offsetDateForBrowser(data.start_time_with_wrong_date),
              end_date_midday: offsetDateForBrowser(data.end_date_midday),
              end_time_with_wrong_date: offsetDateForBrowser(data.end_time_with_wrong_date)
            }))
          })
          .catch(err => {
            setPromoCode({});
          });
      } else {
        axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
        var url = "/teams/" + team.id + "/promo_codes/new?confirm_id=" + confirm.id;

        if(promoCodeToCopy){
          url = (url + "&copy_from_id=" + promoCodeToCopy.id);
        }

        axios.get(url)
          .then(({ data }) => {
            setPromoCode(Object.assign({}, data, {
              start_date_midday: offsetDateForBrowser(data.start_date_midday),
              start_time_with_wrong_date: offsetDateForBrowser(data.start_time_with_wrong_date),
              end_date_midday: offsetDateForBrowser(data.end_date_midday),
              end_time_with_wrong_date: offsetDateForBrowser(data.end_time_with_wrong_date),
              unlimited: true
            }));
          })
          .catch(err => {
            setPromoCode({});
          });
      }
    }
  }, [isOpen, promoCodeToCopy, promoCodeToEdit])

  return (
    <SlidingPane
            isOpen={isOpen}
            className="sliding-pane-container"
            width={"525px"}
            hideHeader={true}
            onRequestClose={() => {
              onClose();
            }}
          >
      <React.Fragment>
        <div className="row m-0 py-4"
              style={{"borderBottom": "2px solid #fafafa"}}>
          <div className="col pl-4">
            <h3 className="mb-0"
                style={{"fontSize": "20px"}}>
              <strong>Add Promo Code</strong>
            </h3>
          </div>
          <div className="col-xs-auto"
                style={{"paddingRight": "39px"}}>
            <a href="#"
                className="text-muted"
                style={{"fontSize": "22px"}}
                onClick={
                  (e) => {
                    e.preventDefault();
                    onClose();
                  }
                }>
              <i className="fal fa-times"></i>
            </a>
          </div>
        </div>
        <form onSubmit={
                (e) => {
                  e.preventDefault();

                  if(!canSubmitPromoCodeForm(promoCode)){
                    return false;
                  }

                  if(promoCode.id){
                    updatePromoCode(csrfToken, team, promoCode, onClose);
                  } else {
                    createPromoCode(csrfToken, team, confirm, promoCode, onClose);
                  }
                }
              }>
          <div className="row m-0">
            <div className="col-12 pt-3">
              <div className="form-group">
                <label className="text-muted mb-1"
                        htmlFor="promo-code-name"
                        style={{"fontSize": "14px"}}>
                  Code Name
                  <span className="text-danger">&nbsp;*</span>
                </label>
                <MaskedInput
                  style={{"textTransform": "uppercase"}}
                  mask={
                    () => {
                      return Array.from(Array(100).keys())
                        .map((i) => /[a-zA-Z0-9]/);
                    }
                  }
                  className="form-control form-control-slim"
                  guide={false}
                  id="promo-code-name"
                  onBlur={() => {}}
                  value={promoCode.name || ""}
                  onChange={(e) => {
                    var newName = e.target.value.toUpperCase();
                    var updated = Object.assign({}, promoCode, {name: newName});

                    setPromoCode(updated);
                  }}
                />
              </div>
              <div className="form-row">
                <div className="col">
                  <div className="form-group mb-2">
                    <div className="custom-control custom-checkbox custom-checkbox-table">
                      <input type="checkbox"
                              className="custom-control-input"
                              style={{"border": "1px solid #e6e6e6"}}
                              checked={promoCode.applies_to_ticket_types || false}
                              onChange={
                                (e) => {
                                  var updated = {
                                    ...promoCode,
                                    applies_to_ticket_types: e.target.checked
                                  }

                                  if(e.target.checked) {
                                    updated.limit_to_one_add_on_per_order = false;
                                  } else {
                                    updated.reveal_hidden_tickets = false;
                                    updated.ticket_type_ids = [];
                                    updated.limit_to_one_ticket_per_order = false;
                                  }

                                  setPromoCode(updated);
                                }
                              }
                              id="promo-code-applies-to-ticket-types" />
                      <label className="custom-control-label"
                              style={{"fontSize": "16px"}}
                              htmlFor="promo-code-applies-to-ticket-types">
                        Applies to Tickets
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {promoCode.applies_to_ticket_types ? (
                <div className="form-row"
                      style={{"marginLeft": "12px"}}>
                  <div className="col">
                    <div className="form-group mb-2">
                      <div className="custom-control custom-checkbox custom-checkbox-table">
                        <input
                          id="promo-code-limit-one-ticket-per-order"
                          type="checkbox"
                          className="custom-control-input"
                          style={{"border": "1px solid #e6e6e6"}}
                          checked={promoCode.limit_to_one_ticket_per_order || false}
                          onChange={e => {
                            var updated = {
                              ...promoCode,
                              limit_to_one_ticket_per_order: e.target.checked
                            }

                            if(e.target.checked) {
                              updated.limit_to_one_add_on_per_order = false;
                              updated.applies_to_add_ons = false;
                            }

                            setPromoCode(updated);
                          }}
                        />
                        <label
                          className="custom-control-label"
                          style={{"fontSize": "16px"}}
                          htmlFor="promo-code-limit-one-ticket-per-order"
                        >
                          Limit to one ticket per order
                        </label>
                      </div>
                    </div>
                    <div className="form-group mb-2">
                      <div className="custom-control custom-checkbox custom-checkbox-table">
                        <input type="checkbox"
                                className="custom-control-input"
                                style={{"border": "1px solid #e6e6e6"}}
                                checked={promoCode.reveal_hidden_tickets || false}
                                onChange={
                                  (e) => {
                                    var updated = Object.assign({}, promoCode, {
                                      reveal_hidden_tickets: e.target.checked,
                                      ticket_type_ids: []
                                    });

                                    setPromoCode(updated);
                                  }
                                }
                                id="promo-code-reveal-hidden-tickets" />
                        <label className="custom-control-label"
                                style={{"fontSize": "16px"}}
                                htmlFor="promo-code-reveal-hidden-tickets">
                          Reveals hidden tickets during checkout
                        </label>
                      </div>
                    </div>
                    {promoCode.reveal_hidden_tickets ? (
                      <div className="form-group mb-2">
                        <Select styles={selectStyles}
                                placeholder={"Select ticket(s)"}
                                isMulti={true}
                                options={
                                  ticketTypes
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map((tt) => ({value: tt.id, label: tt.name}))
                                }
                                value={
                                  ticketTypes
                                    .map((tt) => ({value: tt.id, label: tt.name}))
                                    .filter((tt) => promoCode.ticket_type_ids.includes(tt.value))
                                }
                                onChange={
                                  (selected) => {
                                    var ticketTypeIds = (selected || []).map((tt) => tt.value);
                                    var updated = Object.assign({}, promoCode, {ticket_type_ids: ticketTypeIds});

                                    setPromoCode(updated);
                                  }
                                } />
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
              <div className="form-row">
                <div className="col">
                  <div className="form-group mb-2">
                    <div className="custom-control custom-checkbox custom-checkbox-table">
                      <input type="checkbox"
                              className="custom-control-input"
                              style={{"border": "1px solid #e6e6e6"}}
                              checked={promoCode.applies_to_add_ons || false}
                              onChange={
                                (e) => {
                                  var updated = {
                                    ...promoCode,
                                    applies_to_add_ons: e.target.checked
                                  };

                                  if(e.target.checked) {
                                    updated.limit_to_one_ticket_per_order = false;
                                  } else {
                                    updated.limit_to_one_on_on_per_order = false;
                                  }

                                  setPromoCode(updated);
                                }
                              }
                              id="promo-code-applies-to-add-ons" />
                      <label className="custom-control-label"
                              style={{"fontSize": "16px"}}
                              htmlFor="promo-code-applies-to-add-ons">
                        Applies to Add-ons
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {promoCode.applies_to_add_ons ? (
                <div className="form-row" style={{"marginLeft": "12px"}}>
                  <div className="col">
                    <div className="form-group mb-2">
                      <div className="custom-control custom-checkbox custom-checkbox-table">
                        <input
                          id="promo-code-limit-one-add-on-per-order"
                          type="checkbox"
                          className="custom-control-input"
                          style={{"border": "1px solid #e6e6e6"}}
                          checked={promoCode.limit_to_one_add_on_per_order || false}
                          onChange={e => {
                            var updated = {
                              ...promoCode,
                              limit_to_one_add_on_per_order: e.target.checked
                            }

                            if(e.target.checked) {
                              updated.applies_to_ticket_types = false;
                              updated.ticket_type_ids = [];
                              updated.limit_to_one_ticket_per_order = false;
                            }

                            setPromoCode(updated);
                          }}
                        />
                        <label
                          className="custom-control-label"
                          style={{"fontSize": "16px"}}
                          htmlFor="promo-code-limit-one-add-on-per-order"
                        >
                          Limit to one add on per order
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="form-row">
                <div className="col">
                  <div className="form-group mb-2">
                    <div className="custom-control custom-checkbox custom-checkbox-table">
                      <input type="checkbox"
                              className="custom-control-input"
                              style={{"border": "1px solid #e6e6e6"}}
                              checked={promoCode.unlimited || false}
                              onChange={e => {
                                var updated = {...promoCode, unlimited: e.target.checked}

                                if(e.target.checked) {
                                  updated.limit = null;
                                }

                                setPromoCode(updated);
                              }}
                              id="promo-code-unlimited" />
                      <label className="custom-control-label"
                              style={{"fontSize": "16px"}}
                              htmlFor="promo-code-unlimited">
                        Unlimited
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {!promoCode.unlimited ? (
                <div className="form-row">
                  <div className="col">
                    <div className="form-group">
                      <label className="text-muted mb-1"
                              htmlFor="promo-code-available"
                              style={{"fontSize": "14px"}}>
                        Available Quantity
                        <span className="text-danger">&nbsp;*</span>
                      </label>
                      <MaskedInput className="form-control form-control-slim"
                                    mask={createNumberMask({
                                      prefix: "",
                                      decimalLimit: 0
                                    })}
                                    id="promo-code-available"
                                    onChange={
                                      (e) => {
                                        var updated = Object.assign({}, promoCode, {limit: e.target.value});
                                        setPromoCode(updated);
                                      }
                                    }
                                    value={promoCode.limit || ""} />
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="form-row">
                <div className="col">
                  <div className="form-group">
                    <label className="text-muted mb-1"
                            htmlFor="promo-code-amount"
                            style={{"fontSize": "14px"}}>
                      Discount Amount
                    </label>
                    <CurrencyInput className="form-control form-control-slim"
                                    maskOptions={{
                                      prefix: "",
                                      integerLimit: null
                                    }}
                                    placeholder="$"
                                    value={promoCode.amount_two_decimals || ""}
                                    onChange={
                                      (e) => {
                                        var updated = Object.assign({}, promoCode, {
                                          amount_two_decimals: e.target.value,
                                          percentage: null
                                        });

                                        setPromoCode(updated);
                                      }
                                    }
                                    id="promo-code-amount" />
                  </div>
                </div>
                <div className="col-xs-auto d-flex align-items-center justify-content-center">
                  <div style={{"fontSize": "16px"}}>
                    OR
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label className="text-muted mb-1"
                            htmlFor="promo-code-percentage"
                            style={{"fontSize": "14px"}}>
                      &nbsp;
                    </label>
                    <MaskedInput className={"form-control form-control-slim " + (parseFloatFromString(promoCode.percentage || "") > 100 ? "highlight" : "")}
                                  mask={createNumberMask({
                                    prefix: "",
                                    decimalLimit: 0
                                  })}
                                  id="promo-code-percentage"
                                  placeholder="%"
                                  onChange={
                                    (e) => {
                                      var updated = Object.assign({}, promoCode, {
                                        percentage: e.target.value,
                                        amount_two_decimals: null
                                      });

                                      setPromoCode(updated);
                                    }
                                  }
                                  value={typeof(promoCode.percentage) !== "undefined" ? promoCode.percentage : ""} />
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="ticket-type-availability"
                            className="text-muted mb-1"
                            style={{"fontSize": "14px"}}>
                      When are promo codes available?
                    </label>
                    <select className="form-control form-control-slim"
                            value={promoCode.availability || "Based on show details"}
                            onChange={
                              (e) => {
                                var availability = e.target.value;
                                var params = {availability: availability};

                                var updated = Object.assign({}, promoCode, params);
                                setPromoCode(updated);
                              }
                            }
                            id="promo-code-availability">
                      <option value="Based on show details">
                        Based on show details
                      </option>
                      <option value="Date & Time">
                        Date & Time
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              {promoCode.availability === "Date & Time" ? (
              <React.Fragment>
                <div className="form-row">
                  <div className="col-12">
                    <div className="form-row">
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="promo-code-start-date"
                                  className="text-muted mb-1"
                                  style={{"fontSize": "14px"}}>
                            Start Date
                            <span className="text-danger">&nbsp;*</span>
                          </label>
                          <Flatpickr
                            options={{
                              dateFormat: "M j, Y"
                            }}
                            placeholder={"Select date"}
                            className={"form-control ticketing-date-picker form-control-flatpickr"}
                            value={promoCode.start_date_midday}
                            onChange={dates => {
                              var startDate = parseDateTime(dates[0]);
                              var startTime = parseDateTime(promoCode.start_time_with_wrong_date);
                              var endDate   = parseDateTime(promoCode.end_date_midday);
                              var endTime   = parseDateTime(promoCode.end_time_with_wrong_date);

                              var attrs = {
                                start_date_midday: startDate
                              };

                              if(endDate && endDate < startDate){
                                endDate = "";
                                attrs = Object.assign({}, attrs, {
                                  end_date_midday: endDate
                                });
                              }

                              if(startDate && startTime && endDate && endTime){
                                var startTimeCorrectDate = timeOnDate(startDate, startTime);
                                var endTimeCorrectDate = timeOnDate(endDate, endTime);

                                if(endTimeCorrectDate < startTimeCorrectDate){
                                  attrs = Object.assign({}, attrs, {end_time_with_wrong_date: ""});
                                }
                              }

                              var updated = Object.assign({}, promoCode, attrs);
                              setPromoCode(updated);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="promo-code-start-time"
                                  className="text-muted mb-1"
                                  style={{"fontSize": "14px"}}>
                            Start Time
                            <span className="text-danger">&nbsp;*</span>
                          </label>
                          <DatePicker
                            className="form-control ticketing-date-picker"
                            selected={promoCode.start_time_with_wrong_date}
                            placeholderText={"Select time"}
                            onChange={(time) => {
                              var startDate = parseDateTime(promoCode.start_date_midday);
                              var startTime = parseDateTime(time);
                              var endDate   = parseDateTime(promoCode.end_date_midday);
                              var endTime   = parseDateTime(promoCode.end_time_with_wrong_date);

                              var attrs = {
                                start_time_with_wrong_date: startTime
                              };

                              if(startDate && endDate && endTime){
                                var startTimeCorrectDate = timeOnDate(startDate, startTime);
                                var endTimeCorrectDate = timeOnDate(endDate, endTime);

                                if(endTimeCorrectDate < startTimeCorrectDate){
                                  attrs = Object.assign({}, attrs, {end_time_with_wrong_date: ""});
                                }
                              }

                              var updated = Object.assign({}, promoCode, attrs);
                              setPromoCode(updated);
                            }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-12">
                    <div className="form-row">
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="promo-code-end-date"
                                  className="text-muted mb-1"
                                  style={{"fontSize": "14px"}}>
                            End Date
                            <span className="text-danger">&nbsp;*</span>
                          </label>
                          <Flatpickr
                            options={{
                              dateFormat: "M j, Y",
                              minDate: (promoCode.start_date_midday ? (
                                parseDateTime(promoCode.start_date_midday).setHours(0,0,0,0)
                              ) : null)
                            }}
                            disabled={!promoCode.start_date_midday}
                            placeholder={"Select date"}
                            className={"form-control ticketing-date-picker form-control-flatpickr " + (!promoCode.start_date_midday ? "form-control-disabled" : "")}
                            value={promoCode.end_date_midday}
                            onChange={dates => {
                              var startDate = parseDateTime(promoCode.start_date_midday);
                              var startTime = parseDateTime(promoCode.start_time_with_wrong_date);
                              var endDate   = parseDateTime(dates[0]);
                              var endTime   = parseDateTime(promoCode.end_time_with_wrong_date);

                              var attrs = {
                                end_date_midday: endDate
                              };

                              if(startDate && startTime && endTime){
                                var startTimeCorrectDate = timeOnDate(startDate, startTime);
                                var endTimeCorrectDate = timeOnDate(endDate, endTime);

                                if(endTimeCorrectDate < startTimeCorrectDate){
                                  attrs = Object.assign({}, attrs, {end_time_with_wrong_date: ""});
                                }
                              }

                              var updated = Object.assign({}, promoCode, attrs);
                              setPromoCode(updated);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="promo-code-end-time"
                                  className="text-muted mb-1"
                                  style={{"fontSize": "14px"}}>
                            End Time
                            <span className="text-danger">&nbsp;*</span>
                          </label>
                          <DatePicker
                            className={"form-control ticketing-date-picker " + (!promoCode.end_date_midday || !promoCode.start_time_with_wrong_date ? "form-control-disabled" : "")}
                            selected={promoCode.end_time_with_wrong_date}
                            placeholderText={"Select time"}
                            disabled={!promoCode.end_date_midday || !promoCode.start_time_with_wrong_date}
                            onChange={(time) => {
                              var updated = Object.assign({}, promoCode, {
                                end_time_with_wrong_date: time
                              });

                              setPromoCode(updated);
                            }}
                            filterTime={
                              (time) => {
                                return filterPassedTime(
                                  time,
                                  parseDateTime(promoCode.start_date_midday),
                                  parseDateTime(promoCode.start_time_with_wrong_date),
                                  parseDateTime(promoCode.end_date_midday)
                                );
                              }
                            }
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="form-row">
                    <div className="col-12 form-group">
                      <label htmlFor="ticket-type-start-date"
                              className="text-muted mb-1"
                              style={{"fontSize": "14px"}}>
                        Start Show Detail
                        <span className="text-danger">&nbsp;*</span>
                      </label>
                      <Select
                        options={showActivities.map(showActivity => showActivityOption(showActivity))}
                        placeholder="Select a show detail"
                        value={showActivities.filter(showActivity => {
                          return showActivity.id === promoCode.start_show_activity_id
                        }).map(showActivity => showActivityOption(showActivity))}
                        onChange={option => {
                          setPromoCode({
                            ...promoCode,
                            start_show_activity_id: option.value
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-12 form-group">
                      <label htmlFor="ticket-type-end-date"
                              className="text-muted mb-1"
                              style={{"fontSize": "14px"}}>
                        End Show Detail
                        <span className="text-danger">&nbsp;*</span>
                      </label>
                      <Select
                        options={showActivities.map(showActivity => showActivityOption(showActivity))}
                        placeholder="Select a show detail"
                        value={showActivities.filter(showActivity => {
                          return showActivity.id === promoCode.end_show_activity_id
                        }).map(showActivity => showActivityOption(showActivity))}
                        onChange={option => {
                          setPromoCode({
                            ...promoCode,
                            end_show_activity_id: option.value
                          });
                        }}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
              <div className="row mt-1">
                <div className="col-12 text-right my-4">
                  <a href="#"
                      onClick={
                        (e) => {
                          e.preventDefault();
                          setIsOpen(false);
                          setPromoCode({});
                        }
                      }
                      className="btn btn-outline-primary">
                    <strong>Cancel</strong>
                  </a>
                  <button type="submit"
                          disabled={!canSubmitPromoCodeForm(promoCode)}
                          className="btn btn-primary ml-3">
                    <strong>Save</strong>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    </SlidingPane>
  );
};

PromoCodeForm.propTypes = {
  confirm: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  promoCodeToCopy: PropTypes.object.isRequired,
  promoCodeToEdit: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  showActivities: PropTypes.array.isRequired,
  team: PropTypes.object.isRequired,
  ticketTypes: PropTypes.array.isRequired,
}

export default PromoCodeForm;
