import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const axios = require('axios').default;

const UserMenu = ({
  csrfToken,
  currentUser,
  team,
  teamMemberships
}) => {
  const destroyUserSession = () => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/users/sign_out.json")
      .then(({ data }) => {
        window.location.replace("/users/sign_in");
      });
  };

  const switchCurrentTeam = (teamId) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post(`/teams/${teamId}/switch_current.json`)
      .then(({ data }) => {
        window.location.replace("/dashboard");
      });
  };

  return (
    <div className="row m-0">
      <div className="col-12 p-0"
            style={{
              "background": "white",
              "borderTopRightRadius": "12px",
              "borderTopLeftRadius": "12px",
              "borderBottomRightRadius": "16px",
              "borderBottomLeftRadius": "16px",
              "boxShadow": "0 0 0 1px #E9EAEB"
            }}>
        <div className='position-relative text-left'
              style={{"margin": "12px"}}>
          <a href="/users/edit"
            style={{"fontWeight": "600"}}
            className="text-dark stretched-link">
            <i className="fal fa-user fa-fw"
                aria-hidden
                style={{"marginRight": "10px", "fontSize": "16px"}}></i>
            View Profile
          </a>
        </div>
        {currentUser.admin ? (
          <div className='position-relative text-left'
                style={{"margin": "12px"}}>
            <a href="/admin"
                style={{"fontWeight": "600"}}
                className="text-dark stretched-link">
              <i className="fas fa-server fa-fw"
                  aria-hidden
                  style={{"marginRight": "10px", "fontSize": "16px"}}></i>
              Admin
            </a>
          </div>
        ) : null}
        {teamMemberships.length > 1 ? (
          <div className="col-12"
                style={{
                  "borderTop": "1px solid #E9EAEB",
                  "padding": "8px 15px"
                }}>
            <div className='row position-relative'>
              <div className='col d-flex' style={{"padding": "0px 11px"}}>
                <div style={{"flexGrow": "1"}}>
                  <p className='text-muted mb-0 text-left'
                      style={{"fontSize": "12px", "fontWeight": "600", "marginTop": "3px"}}>
                    Switch team
                  </p>
                </div>
              </div>
            </div>
            <div className='row' style={{"marginTop": "4px", "marginBottom": "2px"}}>
              <div className='col-12'
                    style={{
                      "maxHeight": "170px",
                      "overflowY": "auto",
                      "padding": "0px 5px"
                    }}>
                {teamMemberships.sort((a, b) => a.team_name.localeCompare(b.team_name)).map((teamMembership, index) => (
                  <a key={index}
                      href="#"
                      onClick={
                        (e) => {
                          e.preventDefault();

                          if (teamMembership.current) {
                            return false;
                          }

                          switchCurrentTeam(teamMembership.team_id);
                        }
                      }
                      style={{
                        "padding": "5px 6px",
                        "borderRadius": "4px",
                        "background": (teamMembership.current ? "#fafafa" : "transparent"),
                        "marginBottom": "2px"
                      }}
                      className={"main-menu-link text-truncate text-dark d-flex justify-content-between align-items-center position-relative"}>
                    <span style={{"fontWeight": "600"}}>
                      {teamMembership.team_name}
                    </span>
                    <div className="custom-control custom-radio position-absolute"
                          style={{"right": "-5px"}}>
                      <input type="radio"
                              id={`select-team-${teamMembership.team_id}`}
                              checked={teamMembership.current}
                              onChange={
                                (e) => {
                                  e.preventDefault();
                                }
                              }
                              className="custom-control-input" />
                      <label className="custom-control-label"
                              htmlFor={`select-team-${teamMembership.team_id}`}>
                        &nbsp;
                      </label>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className="col-12 p-0">
        <div className='position-relative text-left'
              style={{"margin": "12px"}}>
          <a href="#"
            style={{"fontWeight": "600"}}
            onClick={
                (e) => {
                  e.preventDefault();
                  destroyUserSession();
                }
            }
            className="text-muted stretched-link">
            <i className="far fa-sign-out fa-fw"
                aria-hidden
                style={{"marginRight": "10px", "fontSize": "16px"}}></i>
            Sign Out
          </a>
        </div>
      </div>
    </div>
  )
};

UserMenu.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  teamMemberships: PropTypes.array.isRequired
};

export default UserMenu;
