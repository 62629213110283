import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import ChooseSeatedTicketTypes from './ChooseSeatedTicketTypes';
import ChooseSeatedAddOns from './ChooseSeatedAddOns';

import Tippy from '@tippyjs/react';

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

import CurrencyInput from '../../CurrencyInput/components/CurrencyInput';
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import {
  MIN_TICKETS,
  MAX_TICKETS
} from '../constants/confirmAttendeesConstants';

const calculateTotalTickets = (ticketTypes, addOns) => {
  var admissionTicketsCount = ticketTypes.reduce((sum, tt) => {
    return sum + (tt.quantity || 0);
  }, 0);

  var addOnTicketsCount = addOns.reduce((sum, ao) => {
    return sum + (ao.quantity || 0);
  }, 0);

  return (
    admissionTicketsCount
      + addOnTicketsCount
  );
}

const canSubmitForm = (orderType, ticketTypes, addOns, ticketReservation) => {
  var totalTicketsCount = calculateTotalTickets(ticketTypes, addOns);

  return (
    orderType
      && orderType.length > 0
      && totalTicketsCount >= MIN_TICKETS
      && totalTicketsCount <= MAX_TICKETS
      && ticketTypes.every((tt) => !tt.quantity || tt.quantity === 0 || tt.face_value.length > 0)
      && addOns.every((ao) => !ao.quantity || ao.quantity === 0 || ao.face_value.length > 0)
      && Object.keys(ticketReservation).length > 0
  );
}

const invalidQuantity = (ticketTypes, addOns) => {
  var totalTicketsCount = calculateTotalTickets(ticketTypes, addOns);

  return (
      totalTicketsCount > MAX_TICKETS
  );
}

const selectedObjectLabel = (object) => {
  var ownLabel;

  switch(object.objectType) {
  case "Seat":
    ownLabel = "Seat";
    break;
  case "Table":
    ownLabel = "Table";
    break;
  case "GeneralAdmissionArea":
    ownLabel = "Area";
    break;
  default:
    ownLabel = object.labels.displayedLabel;
  }

  var labelParts = [];

  if(object.labels.section){
    labelParts.push("Section " + object.labels.section);
  }

  if(object.labels.parent){
    labelParts.push("Row " + object.labels.parent);
  }

  if(object.labels.own){
    labelParts.push(ownLabel + " " + object.labels.own);
  }

  return labelParts.join(", ");
}

const selectedObjectType = (object) => {
  var objectType;

  switch(object.objectType) {
  case "GeneralAdmissionArea":
    objectType = "AddOn";
    break;
  default:
    objectType = object.objectType;
  }

  return objectType;
}

function sortAddOns(a, b) {
  const firstCategoryKey = a.seating_chart_category_key;
  const nextCategoryKey = b.seating_chart_category_key;

  if (firstCategoryKey === null && nextCategoryKey !== null) {
    return -1;
  }

  if (firstCategoryKey !== null && nextCategoryKey === null) {
    return 1;
  }

  if (firstCategoryKey === null && nextCategoryKey === null) {
    return a.position - b.position;
  }

  return a.name.localeCompare(b.name);
}

var debouncedBuildTicketReservation;
const AddAttendees = ({
  csrfToken,
  team,
  currentUser,
  confirm,
  orderType,
  orderTypeChanged,
  ticketTypes,
  addOns,
  ticketTypesChanged,
  addOnsChanged,
  buildTicketReservation,
  ticketReservation,
  createTicketReservation,
  manualTypes,
  seatsIOPublicKey,
  seatsIOChart,
  seatsIOChartChanged,
  seatsIOSelectedObjects,
  seatsIOSelectedObjectsChanged,
  seatingChartEvents
}) => {
  useEffect(() => {
    debouncedBuildTicketReservation = _.debounce((csrfToken, team, confirm, orderType, ticketTypes, addOns) => {
      buildTicketReservation(csrfToken, team, confirm, orderType, ticketTypes, addOns);
    }, 250);
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <p style={{"fontSize": "20px", "marginBottom": "3px"}}>
            <strong>Add Attendees</strong>
          </p>
          <p>
            Manually add attendee for comp tickets or box office payments
          </p>
          <hr style={{
                "borderTop": "2px solid #f1f1f1",
                "margin": "30px 0px 25px 0px"
              }} />
        </div>
      </div>
      <form onSubmit={
              (e) => {
                e.preventDefault();

                if(!canSubmitForm(orderType, ticketTypes, addOns, ticketReservation)){
                  return false;
                }

                createTicketReservation(csrfToken, team, confirm, orderType, ticketTypes, addOns, seatsIOChart);
              }
            }>
        <div className="form-row">
          <div className="col-12 col-lg-6 col-xl-4">
            <label className="mb-1"
                   htmlFor="ticket-reservation-manual-type">
              Order Type
            </label>
            <select className="custom-select"
                    value={orderType || ""}
                    onChange={
                      (e) => {
                        var updated = e.target.value;

                        orderTypeChanged(updated);
                        debouncedBuildTicketReservation(csrfToken, team, confirm, updated, ticketTypes, addOns);
                      }
                    }
                    style={{
                      "border": "1px solid #e6e6e6"
                    }}
                    id="ticket-reservation-manual-type">
              {manualTypes.map((option, index) =>
                <option key={index} value={option}>{option}</option>
              )}
            </select>
          </div>
        </div>
        {confirm.seating_chart_id ? (
          <div className="form-row">
            <div className="col-12" style={{"marginTop": "38px"}}>
              <label className="mb-2" style={{"fontSize": "18px"}}>
                <strong>Choose Admission Tickets</strong>
              </label>
              <ChooseSeatedTicketTypes team={team}
                                       csrfToken={csrfToken}
                                       confirm={confirm}
                                       ticketTypes={ticketTypes}
                                       ticketTypesChanged={ticketTypesChanged}
                                       addOns={addOns}
                                       seatsIOPublicKey={seatsIOPublicKey}
                                       seatsIOChart={seatsIOChart}
                                       seatsIOChartChanged={seatsIOChartChanged}
                                       seatsIOSelectedObjects={seatsIOSelectedObjects}
                                       seatsIOSelectedObjectsChanged={seatsIOSelectedObjectsChanged}
                                       selectedObjectLabel={selectedObjectLabel}
                                       selectedObjectType={selectedObjectType}
                                       orderType={orderType}
                                       buildTicketReservation={buildTicketReservation} />
            </div>
          </div>
        ) : null}
        {confirm.add_on_seating_chart_id ? (
          <div className="form-row">
            <div className="col-12" style={{"marginTop": "38px"}}>
              <label className="mb-2" style={{"fontSize": "18px"}}>
                <strong>Choose Add-on Tickets</strong>
              </label>
              <ChooseSeatedAddOns team={team}
                                  csrfToken={csrfToken}
                                  confirm={confirm}
                                  ticketTypes={ticketTypes}
                                  addOns={addOns}
                                  addOnsChanged={addOnsChanged}
                                  buildTicketReservation={buildTicketReservation}
                                  seatsIOPublicKey={seatsIOPublicKey}
                                  orderType={orderType}
                                  selectedObjectLabel={selectedObjectLabel}
                                  selectedObjectType={selectedObjectType} />
            </div>
          </div>
        ) : null}
        {seatingChartEvents.map((seatingChartEvent, index) => (
          <div className="form-row" key={index}>
            <div className="col-12" style={{"marginTop": "15px"}}>
              <label className="mb-2" style={{"fontSize": "16px"}}>
                <strong>{seatingChartEvent.name}</strong>
              </label>
              <ChooseSeatedAddOns team={team}
                                  csrfToken={csrfToken}
                                  confirm={confirm}
                                  ticketTypes={ticketTypes}
                                  addOns={addOns}
                                  addOnsChanged={addOnsChanged}
                                  buildTicketReservation={buildTicketReservation}
                                  seatsIOPublicKey={seatsIOPublicKey}
                                  orderType={orderType}
                                  selectedObjectLabel={selectedObjectLabel}
                                  checkoutGroupItem={{
                                    item_id: seatingChartEvent.id,
                                    extra: { seatsio_event_id: seatingChartEvent.seatsio_event_id }
                                  }}
                                  selectedObjectType={selectedObjectType} />
            </div>
          </div>
        ))}
        <div className="form-row">
          <div className="col-12" style={{"marginTop": "38px"}}>
            <table className="table vertical-middle table-responsive"
                   style={{tableLayout: "fixed"}}>
              <colgroup>
                <col span={1} style={{"width": "35%"}} />
                <col span={1} style={{"width": "15%"}} />
                <col span={1} style={{"width": "10%"}} />
                <col span={1} style={{"width": "20%"}} />
                <col span={1} style={{"width": "20%"}} />
              </colgroup>
              <thead>
                <tr style={{"background": "#f9f9f9"}}>
                  <th className="font-weight-normal">Ticket Type</th>
                  <th className="font-weight-normal">Sold</th>
                  <th className="font-weight-normal">Price</th>
                  <th className="font-weight-normal">Quantity</th>
                  <th className="font-weight-normal">
                    Face Value
                    <Tippy content={
                             <div style={{
                                    "fontSize": "14px"
                                  }}>
                               You can modify the Face Value to display a different value on the customer's ticket. This does not change the actual price of the ticket for settlement purposes.
                             </div>
                           }
                           placement={'top'}
                           maxWidth={200}
                           animation={'shift-away'}
                           theme='material'>
                      <i className="fas fa-info-circle extra-small ml-1 text-muted"
                         style={{"fontSize": "14px"}}>
                      </i>
                    </Tippy>
                  </th>
                </tr>
              </thead>
              <tbody style={{"fontSize": "16px"}}>
                {ticketTypes.sort((a, b) => a.position - b.position).map((ticketType, index) =>
                  <tr key={index} style={{"borderTop": "none", "borderBottom": "1px solid #ededed"}}>
                    <td title={ticketType.name}
                        style={{"padding": "5px 12px"}}
                        className="overflow-ellipsis">
                      {ticketType.name}
                    </td>
                    <td className="text-muted"
                        style={{"padding": "5px 12px"}}>
                      {ticketType.number_of_tickets_sold}/{ticketType.available}
                    </td>
                    <td className="text-muted"
                        style={{"padding": "5px 12px"}}>
                      {ticketType.price_to_currency}
                    </td>
                    <td style={{"padding": "5px 12px"}}>
                      <MaskedInput className={"form-control text-right " + (invalidQuantity(ticketTypes, addOns) ? "highlight" : "")}
                                    style={{
                                      "border": "1px solid #e6e6e6"
                                    }}
                                    mask={createNumberMask({
                                      prefix: "",
                                      decimalLimit: 0,
                                      integerLimit: 3
                                    })}
                                    placeholder={"0"}
                                    disabled={confirm.seating_chart_id}
                                    onChange={
                                      (e) => {
                                        var quantity = parseInt(e.target.value || "0");

                                        var updated = ticketTypes.map((tt) => {
                                          if(tt.id === ticketType.id){
                                            return Object.assign({}, ticketType, {quantity: quantity});
                                          } else {
                                            return tt;
                                          }
                                        });

                                        ticketTypesChanged(updated);
                                        debouncedBuildTicketReservation(csrfToken, team, confirm, orderType, updated, addOns);
                                      }
                                    }
                                    value={ticketType.quantity || ""} />
                    </td>
                    <td style={{"padding": "5px 12px"}}>
                      <div className="input-group flex-nowrap">
                        <div className="input-group-prepend">
                          <span className="input-group-text text-muted"
                                style={{
                                  "borderLeft": "1px solid #e6e6e6",
                                  "borderTop": "1px solid #e6e6e6",
                                  "borderBottom": "1px solid #e6e6e6",
                                  "borderRight": "none",
                                  "fontSize": "14px",
                                  "background": "white"
                                }}>
                            <i className="fas fa-dollar-sign"></i>
                          </span>
                        </div>
                        <CurrencyInput className={"form-control text-right " + (ticketType.quantity && ticketType.quantity > 0 && !ticketType.face_value ? "highlight" : "")}
                                        style={{
                                          "borderRight": "1px solid #e6e6e6",
                                          "borderTop": "1px solid #e6e6e6",
                                          "borderBottom": "1px solid #e6e6e6",
                                          "borderLeft": "none",
                                          "minWidth": "100px",
                                        }}
                                        maskOptions={{
                                          prefix: "",
                                          integerLimit: null
                                        }}
                                        placeholder={"0"}
                                        value={ticketType.face_value || ""}
                                        onChange={
                                          (e) => {
                                            var updated = ticketTypes.map((tt) => {
                                              if(tt.id === ticketType.id){
                                                return Object.assign({}, ticketType, {face_value: e.target.value});
                                              } else {
                                                return tt;
                                              }
                                            });

                                            ticketTypesChanged(updated);
                                            debouncedBuildTicketReservation(csrfToken, team, confirm, orderType, updated, addOns);
                                          }
                                        }
                                        id="offer-dollar-amount"
                                        aria-describedby="offer-dollar-amount-dollar-sign" />
                      </div>
                    </td>
                  </tr>
                )}
                {addOns.sort(sortAddOns).map((addOn, index) =>
                  <tr key={index} style={{"borderTop": "none", "borderBottom": "1px solid #ededed"}}>
                    <td title={addOn.name}
                        className="overflow-ellipsis"
                        style={{"padding": "5px 12px"}}>
                      {addOn.name}
                    </td>
                    <td className="text-muted"
                        style={{"padding": "5px 12px"}}>
                      {addOn.number_of_tickets_sold}/{addOn.available}
                    </td>
                    <td className="text-muted"
                        style={{"padding": "5px 12px"}}>
                      {addOn.price_to_currency}
                    </td>
                    <td style={{"padding": "5px 12px"}}>
                      <MaskedInput className={"form-control text-right " + (invalidQuantity(ticketTypes, addOns) ? "highlight" : "")}
                                   style={{
                                     "border": "1px solid #e6e6e6"
                                   }}
                                   mask={createNumberMask({
                                     prefix: "",
                                     decimalLimit: 0,
                                     integerLimit: 3
                                   })}
                                   placeholder={"0"}
                                   disabled={addOn.seating_chart_category_key && addOn.seating_chart_category_key.length > 0}
                                   onChange={
                                     (e) => {
                                       var quantity = parseInt(e.target.value || "0");

                                       var updated = addOns.map((ao) => {
                                         if(ao.id === addOn.id){
                                           return Object.assign({}, addOn, {quantity: quantity});
                                         } else {
                                           return ao;
                                         }
                                       });

                                       addOnsChanged(updated);
                                       debouncedBuildTicketReservation(csrfToken, team, confirm, orderType, ticketTypes, updated);
                                     }
                                   }
                                   value={addOn.quantity || ""} />
                    </td>
                    <td style={{"padding": "5px 12px"}}>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text text-muted"
                                style={{
                                  "borderLeft": "1px solid #e6e6e6",
                                  "borderTop": "1px solid #e6e6e6",
                                  "borderBottom": "1px solid #e6e6e6",
                                  "borderRight": "none",
                                  "fontSize": "14px",
                                  "background": "white"
                                }}>
                            <i className="fas fa-dollar-sign"></i>
                          </span>
                        </div>
                        <CurrencyInput className={"form-control text-right " + (addOn.quantity && addOn.quantity > 0 && !addOn.face_value ? "highlight" : "")}
                                       style={{
                                         "borderRight": "1px solid #e6e6e6",
                                         "borderTop": "1px solid #e6e6e6",
                                         "borderBottom": "1px solid #e6e6e6",
                                         "borderLeft": "none",
                                       }}
                                       maskOptions={{
                                         prefix: "",
                                         integerLimit: null
                                       }}
                                       placeholder={"0"}
                                       value={addOn.face_value || ""}
                                       onChange={
                                         (e) => {
                                           var updated = addOns.map((ao) => {
                                             if(ao.id === addOn.id){
                                               return Object.assign({}, addOn, {face_value: e.target.value});
                                             } else {
                                               return ao;
                                             }
                                           });

                                           addOnsChanged(updated);
                                           debouncedBuildTicketReservation(csrfToken, team, confirm, orderType, ticketTypes, updated);
                                         }
                                       }
                                       id="offer-dollar-amount"
                                       aria-describedby="offer-dollar-amount-dollar-sign" />
                      </div>
                    </td>
                  </tr>
                )}
                <tr className="border-0">
                  <td colSpan={4}
                      style={{"padding": "5px 12px"}}
                      className="text-right">
                    <strong>Total Value</strong>
                  </td>
                  <td style={{"padding": "5px 12px"}}>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text text-muted"
                              style={{
                                "borderLeft": "1px solid #e6e6e6",
                                "borderTop": "1px solid #e6e6e6",
                                "borderBottom": "1px solid #e6e6e6",
                                "borderRight": "none",
                                "fontSize": "14px"
                              }}>
                          <i className="fas fa-dollar-sign"></i>
                        </span>
                      </div>
                      <input type="text"
                             className="form-control text-right"
                             style={{
                               "borderRight": "1px solid #e6e6e6",
                               "borderTop": "1px solid #e6e6e6",
                               "borderBottom": "1px solid #e6e6e6",
                               "borderLeft": "none",
                             }}
                             disabled={true}
                             value={
                               Object.keys(ticketReservation).length > 0 ? (
                                 formatter
                                   .format(ticketReservation.total)
                                   .replace("$", "")
                               ) : ""
                             }
                             placeholder="0" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="form-row text-right">
          <div className="col-12"
               style={{"marginTop": "25px"}}>
            <button type="submit"
                    disabled={!canSubmitForm(orderType, ticketTypes, addOns, ticketReservation)}
                    className="btn btn-primary">
              Next
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

AddAttendees.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  orderType: PropTypes.string,
  orderTypeChanged: PropTypes.func.isRequired,
  ticketTypes: PropTypes.array,
  addOns: PropTypes.array,
  ticketTypesChanged: PropTypes.func.isRequired,
  addOnsChanged: PropTypes.func.isRequired,
  buildTicketReservation: PropTypes.func.isRequired,
  ticketReservation: PropTypes.object,
  createTicketReservation: PropTypes.func.isRequired,
  manualTypes: PropTypes.array,
  seatsIOPublicKey: PropTypes.string.isRequired,
  seatsIOChart: PropTypes.object,
  seatsIOChartChanged: PropTypes.func.isRequired,
  seatsIOSelectedObjects: PropTypes.array,
  seatsIOSelectedObjectsChanged: PropTypes.func.isRequired,
  seatingChartEvents: PropTypes.array
};

export default AddAttendees;
