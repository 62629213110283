import PropTypes from 'prop-types';
import React, { useState } from 'react';
import FeeRuleTable from './FeeRuleTable';

import MaskedInput from 'react-text-mask'

const SettingsTab = ({
  csrfToken,
  confirm,
  team,
  updateConfirm,
  autoSaveConfirm,
  feeRuleSets,
  confirmChanged
}) => {
  const [maxTicketsAllowed, setMaxTicketsAllowed] = useState(confirm.max_tickets_per_email);

  const canSubmitForm = (
    parseInt(maxTicketsAllowed || 0) !== parseInt(confirm.max_tickets_per_email || 0)
  );

  return (
    <div>
      <form onSubmit={
              (e) => {
                e.preventDefault();

                if(canSubmitForm) {
                  var updated = {
                    ...confirm,
                    max_tickets_per_email: maxTicketsAllowed
                  }

                  updateConfirm(updated);
                  autoSaveConfirm(csrfToken, team, updated);
                }
              }
            }>
        <div className='row'>
          <div className='col-12' style={{"marginTop": "20px"}}>
            <p className='mb-2' style={{"fontSize": "14px"}}>
              <strong>Maximum Tickets Allowed</strong>
            </p>
            <p className='mb-0 text-muted' style={{"fontSize": "10px", "maxWidth": "450px"}}>
              Define the maximum number of tickets that can be purchased by a fan across multiple orders. If a fan attempts to purchase more than the maximum number of tickets allowed, they will receive an alert notifying them that the event has a maximum and the order will not process. 
            </p>
            <div className="row">
              <div className="col-12 col-md-6">
                <label htmlFor="venue-ownership-max-tickets-per-email"
                        className="text-muted small"
                        style={{"color": "#B3B3B3", "marginTop": "18px"}}>
                  Maximum Tickets Allowed
                </label>
                <MaskedInput
                  style={{
                    "textTransform": "uppercase",
                    "fontSize": "14px",
                    "color": "black",
                    "borderWidth": "1px",
                    "width": "96px",
                    "borderRadius": "4px"
                  }}
                  mask={
                    () => {
                      var regex = [];

                      Array.from(Array(3).keys())
                        .map((i) => {
                          regex.push(/[0-9]/);
                        });

                      return regex;
                    }
                  }
                  className="form-control"
                  guide={false}
                  onBlur={() => {}}
                  value={maxTicketsAllowed || ""}
                  onChange={
                    (e) => {
                      setMaxTicketsAllowed(e.target.value);
                    }
                  }
                  id="venue-ownership-max-tickets-per-email"
                  placeholder="0"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md" style={{"marginTop": "20px"}}>
            <button
              type="submit"
              className="btn btn-primary btn-sm"
              disabled={!canSubmitForm}
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>
      <hr style={{
                    "borderTop": "1px solid #e6e6e6",
                    "marginTop": "25px",
                    "marginBottom": "32px"
                  }} />
      <div className='row'>
        <div className='col-12' style={{"marginTop": "20px"}}>
          <p className='mb-2' style={{"fontSize": "14px"}}>
            <strong>Google reCAPTCHA</strong>
          </p>
          <p className='text-muted' style={{"fontSize": "10px", "maxWidth": "450px"}}>
            reCAPTCHA is a security service from Google that protects your event from fraud and abuse. Opendate recommends enabling reCAPTCHA for high demand events to prevent bots from purchasing tickets.
          </p>
          <div className="custom-control custom-switch custom-switch-md d-flex align-items-center">
            <input type="checkbox"
                    className="custom-control-input"
                    checked={
                      confirm.enable_recaptcha
                    }
                    onChange={
                      (e) => {
                        var updated = {
                          ...confirm,
                          enable_recaptcha: e.target.checked
                        };

                        confirmChanged(updated);
                        autoSaveConfirm(csrfToken, team, updated);
                      }
                    }
                    id="confirm-enable-recaptcha" />
            <label className="custom-control-label pb-0"
                    style={{
                      "fontSize": "14px",
                      "paddingTop": "5px",
                      "paddingLeft": "20px"
                    }}
                    htmlFor="confirm-enable-recaptcha">
              {confirm.enable_recaptcha ? "reCAPTCHA Enabled" : "reCAPTCHA Disabled"}
            </label>
          </div>
        </div>
      </div>
      <hr style={{
                    "borderTop": "1px solid #e6e6e6",
                    "marginTop": "25px",
                    "marginBottom": "32px"
                  }} />
      <div className='row'>
        <div className='col-12' style={{"marginTop": "20px"}}>
          <p className='mb-2' style={{"fontSize": "14px"}}>
            <strong>All-In Pricing</strong>
          </p>
          <p className='text-muted' style={{"fontSize": "10px", "maxWidth": "450px"}}>
            Display the total price of the ticket to the fan, including all fees and taxes.
          </p>
          <div className="custom-control custom-switch custom-switch-md d-flex align-items-center">
            <input
              id="confirm-enable-all-in-pricing"
              type="checkbox"
              className="custom-control-input"
              checked={confirm.enable_all_in_pricing}
              onChange={e => {
                const updated = {
                  ...confirm,
                  enable_all_in_pricing: e.target.checked
                };

                confirmChanged(updated);
                autoSaveConfirm(csrfToken, team, updated);
              }}
            />
            <label
              htmlFor="confirm-enable-all-in-pricing"
              className="custom-control-label pb-0"
              style={{
                "fontSize": "14px",
                "paddingTop": "5px",
                "paddingLeft": "20px"
              }}
            >
              {confirm.enable_all_in_pricing ? "All-In Pricing Enabled" : "All-In Pricing Disabled"}
            </label>
          </div>
        </div>
      </div>
      <hr style={{
        "borderTop": "1px solid #e6e6e6",
        "marginTop": "25px",
        "marginBottom": "32px"
      }} />
      <FeeRuleTable
        csrfToken={csrfToken}
        confirm={confirm}
        feeRuleSets={feeRuleSets}
        team={team}
      />
    </div>
  );
}

SettingsTab.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  confirm: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  updateConfirm: PropTypes.func.isRequired,
  autoSaveConfirm: PropTypes.func.isRequired,
  feeRuleSets: PropTypes.array,
  confirmChanged: PropTypes.func.isRequired
};

export default SettingsTab;
