import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import AddOnsTable from "./AddOnsTable";

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

var _ = require('lodash');
import { toast } from 'react-toastify';
const axios = require('axios').default;

import RichEditor from '../../RichEditor/components/RichEditor';
import {EditorState} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';

import Popup from "reactjs-popup";
import SlidingPane from "react-sliding-pane";
import Select from 'react-select'
import {DebounceInput} from 'react-debounce-input';
import Flatpickr from "react-flatpickr";

import CurrencyInput from '../../CurrencyInput/components/CurrencyInput';
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import { offsetDateForBrowser } from '../../../shared/timeZoneLogic';

const modalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "840px",
  "padding": "0px"
}

const addOnsIntroModalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "840px",
  "padding": "0px"
}

const duplicatingAddOnModalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "492px",
  "padding": "18px 16px"
}

const addOnOptionsPopupContentStyle = {
  "border": "none",
  "borderRadius": "4px",
  "boxShadow": "0 0 8px 2px rgba(0,0,0,0.03), 0 16px 24px 0 rgba(0,0,0,0.10)",
  "width": "200px",
  "padding": "12px 16px"
}

const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "transparent",
    zIndex: "999"
  }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #e6e6e6"
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: "9999 !important" })
};

const parseDateTime = (dateTime) => {
  if (dateTime){
    if (typeof(dateTime) === "string") {
      var dateObj = new Date(dateTime);
      return dateObj;
    } else {
      return dateTime;
    }
  }
}

const parseFloatFromString = (value) => {
  if(typeof(value) === "number"){
    return value;
  }

  return parseFloat(value.split(",").join(""));
}

const minPerOrderMoreThanMax = (addOn) => {
  var minimumPerOrder;
  if(addOn.minimum_per_order){
    minimumPerOrder = parseFloatFromString(addOn.minimum_per_order);
  }

  var maximumPerOrder;
  if(addOn.maximum_per_order){
    maximumPerOrder = parseFloatFromString(addOn.maximum_per_order);
  }

  return (
    minimumPerOrder
      && maximumPerOrder
      && minimumPerOrder > maximumPerOrder
  );
};

const canSubmitForm = (addOn) => {
  var minimumPerOrder;
  if(addOn.minimum_per_order){
    minimumPerOrder = parseFloatFromString(addOn.minimum_per_order);
  }

  var maximumPerOrder;
  if(addOn.maximum_per_order){
    maximumPerOrder = parseFloatFromString(addOn.maximum_per_order);
  }

  var startTime = addOn.start_time;
  var endTime   = addOn.end_time;

  return (
    addOn
      && addOn.name
      && addOn.available
      && addOn.price_two_decimals
      && minimumPerOrder
      && maximumPerOrder
      && minimumPerOrder <= maximumPerOrder
      && ((
        addOn.availability === "Date & Time"
        && startTime
        && endTime
        && startTime <= endTime
      ) || (
        addOn.availability === "Based on show details"
        && addOn.start_show_activity_id
        && addOn.end_show_activity_id
      ))
  );
};

const canChangeAddOnSeatingChart = (confirm, isLoadingDefaultAddOns) => {
  return (
    confirm
      && confirm.can_change_add_on_ticketing_arrangement
      && !isLoadingDefaultAddOns
  )
}

const addOnIsSeated = (addOn) => {
  return (
    addOn
      && addOn.seating_chart_category_key
      && addOn.seating_chart_category_key.length > 0
  )
}

const isSharingInventory = (addOns, addOn) => {
  return (
    addOn.share_inventory_with_id
      || addOns.find((ao) => ao.share_inventory_with_id === addOn.id)
  )
}

const showActivityOption = (showActivity) => {
  const formattedTime = offsetDateForBrowser(showActivity.start_time).toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit"
  });

  const label = showActivity.set ? (
    `${showActivity.name} - ${formattedTime}` 
  ): (
    <>
      {showActivity.name}
      <span style={{ color: "#FF5555" }}> Date not set yet</span>
    </>
  );

  return {
    value: showActivity.id,
    label,
  }
}

var debouncedBuildExampleTicket;

const AddOnsTab = ({
  csrfToken,
  team,
  currentUser,
  confirm,
  addOns,
  addOnForForm,
  fetchNewAddOn,
  addOnForFormChanged,
  ticketTypes,
  createAddOn,
  fetchAddOnToEdit,
  deleteAddOn,
  updateAddOn,
  addOnDragged,
  addOnSeatingCharts,
  autoSaveConfirm,
  updateConfirm,
  isEditingAddOnsIntro,
  isEditingAddOnsIntroChanged,
  addOnsIntroEditorState,
  addOnsIntroEditorStateChanged,
  createDefaultAddOns,
  isLoadingDefaultAddOns,
  isEditingAddOnsAlertMessage,
  isEditingAddOnsAlertMessageChanged,
  addOnsAlertMessageEditorState,
  addOnsAlertMessageEditorStateChanged,
  showActivities,
  manualTaxes,
  manualTaxShouldBeChecked,
  addOnAdded,
  seatingChartEvents
}) => {
  const standardAddOns = addOns.filter((addOn) => !addOnIsSeated(addOn));
  const seatedAddOns = addOns.filter((addOn) => addOnIsSeated(addOn));
  const [exampleTicket, setExampleTicket] = useState({});
  const [duplicatingAddOnId, setDuplicatingAddOnId] = useState(null);
  const [isSavingDuplicateAddOn, setIsSavingDuplicateAddOn] = useState(false);

  const duplicateAddOn = (csrfToken, team, addOnId) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    setIsSavingDuplicateAddOn(true);

    return axios.post(`/teams/${team.id}/add_ons/${addOnId}/dup_with_shared_inventory`, {})
      .then(({ data }) => {
        addOnAdded(data);
      })
      .catch((error) => {
        toast.error("Something went wrong. Try again.", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      }).finally(() => {
        setDuplicatingAddOnId(null);
        setIsSavingDuplicateAddOn(false);
      });
  };

  const buildExampleTicket = (csrfToken, team, confirm, addOn) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var params = {
      calendar_event_id: confirm.id,
      price: (addOn.price_two_decimals ? parseFloatFromString(addOn.price_two_decimals) : null),
      absorb_fees: addOn.absorb_fees
    };

    return axios.post("/teams/" + team.id + "/add_ons/build_ticket", {
        add_on: params
      })
      .then(({ data }) => {
        setExampleTicket(data);
      });
  };

  useEffect(() => {
    debouncedBuildExampleTicket = _.debounce((csrfToken, team, confirm, addOn) => {
      buildExampleTicket(csrfToken, team, confirm, addOn)
    }, 500);
  }, []);

  return (
    <div>
      <div className="row mt-4">
        <div className="col-12 d-flex justify-content-end justify-content-md-end"
            style={{
              "marginBottom": "20px",
              "paddingRight": "15px"
            }}>
          <Popup arrow={false}
                offsetY={5}
                position="bottom right"
                contentStyle={addOnOptionsPopupContentStyle}
                onOpen={
                  (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }
                }
                trigger={open => (
                  <a className="btn btn-light small btn-light-solid-hover"
                      style={{
                        "borderRadius": "4px"
                      }}
                      onClick={
                        (e) => {
                          e.preventDefault();
                        }
                      }
                      href="#">
                    <strong>
                      Options
                      <i className="fas fa-cog"
                          style={{"marginLeft": "13px"}}></i>
                    </strong>
                  </a>
                )} >
            {close => (
              <div className='row text-left'>
                <div className="col-12">
                  <div className="custom-control custom-checkbox custom-checkbox-table mb-2">
                    <div className="row">
                      <div className="col">
                        <input type="checkbox"
                                className="custom-control-input"
                                checked={confirm.has_add_ons_intro}
                                onChange={
                                  (e) => {
                                    var updated = Object.assign({}, confirm, {
                                      has_add_ons_intro: e.target.checked
                                    });

                                    updateConfirm(updated);
                                    autoSaveConfirm(csrfToken, team, updated);
                                  }
                                }
                                id="confirm-has-add-ons-intro" />
                        <label className="custom-control-label text-dark"
                                style={{"fontSize": "14px"}}
                                htmlFor="confirm-has-add-ons-intro">
                          Add-ons intro
                        </label>
                      </div>
                      <div className="col-xs-auto"
                            style={{"paddingRight": "15px"}}>
                        <a href="#"
                            onClick={
                              (e) => {
                                e.preventDefault();

                                if(confirm.has_add_ons_intro){
                                  isEditingAddOnsIntroChanged(true);
                                }
                              }
                            }
                            className={"ml-3 " + (confirm.has_add_ons_intro ? "text-primary" : "text-muted disabled")}>
                          <i className="fas fa-pencil"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="custom-control custom-checkbox custom-checkbox-table">
                    <div className="row">
                      <div className="col">
                        <input type="checkbox"
                                className="custom-control-input"
                                checked={confirm.has_add_ons_alert_message}
                                onChange={
                                  (e) => {
                                    var updated = Object.assign({}, confirm, {
                                      has_add_ons_alert_message: e.target.checked
                                    });

                                    updateConfirm(updated);
                                    autoSaveConfirm(csrfToken, team, updated);
                                  }
                                }
                                id="confirm-has-add-ons-alert-message" />
                        <label className="custom-control-label text-dark"
                                style={{"fontSize": "14px"}}
                                htmlFor="confirm-has-add-ons-alert-message">
                          Alert Message
                        </label>
                      </div>
                      <div className="col-xs-auto"
                            style={{"paddingRight": "15px"}}>
                        <a href="#"
                            onClick={
                              (e) => {
                                e.preventDefault();

                                if(confirm.has_add_ons_alert_message){
                                  isEditingAddOnsAlertMessageChanged(true);
                                }
                              }
                            }
                            className={"ml-3 " + (confirm.has_add_ons_alert_message ? "text-primary" : "text-muted disabled")}>
                          <i className="fas fa-pencil"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Popup>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md">
          <p className="mb-0"
              style={{"fontSize": "16px"}}>
            <strong>Standard</strong>
          </p>
          <p className="text-muted small mb-1">
            Sell additional items like ticket upgrades, parking, and merch packages.
          </p>
        </div>
        {standardAddOns.length > 0 ? (
          <div className="col-12 col-md-auto">
            <div className="text-right">
              <a href="#"
                  style={{
                    "borderWidth": "1px",
                    "borderRadius": "4px"
                  }}
                  onClick={
                    (e) => {
                      e.preventDefault();
                      fetchNewAddOn(csrfToken, team, confirm);
                    }
                  }
                  className="btn btn-outline-primary small">
                <strong>Add Add-on</strong>
              </a>
            </div>
          </div>
        ) : null}
      </div>
      <div className='row'>
        <div className='col-12'>
          {standardAddOns.length === 0 ? (
            <div className="row">
              <div className="col-12"
                  style={{"marginTop": "12px"}}>
                <a href="#"
                  onClick={
                    (e) => {
                      e.preventDefault();
                      fetchNewAddOn(csrfToken, team, confirm);
                    }
                  }
                  style={{
                    "borderRadius": "4px",
                    "fontSize": "14px"
                  }}
                  className="btn btn-primary">
                  <strong>Create Add-on</strong>
                </a>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-12">
                <AddOnsTable csrfToken={csrfToken}
                              team={team}
                              currentUser={currentUser}
                              confirm={confirm}
                              addOns={standardAddOns}
                              fetchNewAddOn={fetchNewAddOn}
                              fetchAddOnToEdit={fetchAddOnToEdit}
                              deleteAddOn={deleteAddOn}
                              updateAddOn={updateAddOn}
                              autoSaveConfirm={autoSaveConfirm}
                              createDefaultAddOns={createDefaultAddOns}
                              updateConfirm={updateConfirm}
                              canChangeAddOnSeatingChart={false}
                              setDuplicatingAddOnId={setDuplicatingAddOnId}
                              isSharingInventory={isSharingInventory}
                              addOnDragged={addOnDragged} />
              </div>
            </div>
          )}
        </div>
      </div>
      {addOnSeatingCharts.length > 0 ? (
        <>
          <div className="row">
            <div className="col-12">
              <hr style={{
                    "borderTop": "1px solid #979797",
                    "marginTop": "20px",
                    "marginBottom": "20px"
                  }} />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md">
              <p className="mb-0"
                  style={{"fontSize": "16px"}}>
                <strong>Seated</strong>
              </p>
              <p className="text-muted small mb-1">
                Create a seated add-on to allow fans to purchase an upgraded seat.
              </p>
            </div>
            {confirm.add_on_seating_chart_id && addOnSeatingCharts.length > 0 ? (
              <div className="col-12 col-md-auto d-flex justify-content-end"
                    style={{
                      "marginBottom": "13px",
                      "paddingRight": "15px"
                    }}>
                <select className="form-control form-control-sm text-primary small"
                        style={{
                          "border": "1px solid #1982C4",
                          "borderRadius": "4px",
                          "fontWeight": "bold",
                          "maxWidth": "200px",
                          "minWidth": "100px"
                        }}
                        disabled={!canChangeAddOnSeatingChart(confirm, isLoadingDefaultAddOns)}
                        onChange={
                          (e) => {
                            var seatingChart = {id: e.target.value};
                            var updated = Object.assign({}, confirm, {
                              add_on_seating_chart_id: seatingChart.id
                            });

                            autoSaveConfirm(csrfToken, team, updated, true)
                              .then(() => {
                                createDefaultAddOns(csrfToken, team, updated, seatingChart, false);
                              })
                              .then(() => {
                                updateConfirm(updated);
                              })
                              .catch((error) => {});
                          }
                        }
                        value={confirm.add_on_seating_chart_id || ""}>
                  {addOnSeatingCharts.map((seatingChart, index) =>
                    <option value={seatingChart.id} key={index}>
                    {seatingChart.name}
                  </option>
                  )}
                </select>
              </div>
            ) : null}
          </div>
          {isLoadingDefaultAddOns ? (
            <div className="form-row mt-3">
              <div className="col-12 text-center">
                <img src="/uploading-loading.gif"
                    style={{width: "50px"}} />
              </div>
            </div>
          ) : addOnSeatingCharts.length > 0 && !confirm.add_on_seating_chart_id ? (
            <div className="form-row" style={{"marginTop": "14px"}}>
              {addOnSeatingCharts.map((seatingChart, index) =>
                <div className="col-12 col-xl-6" key={index}>
                  <div className="card clickable clickable-card"
                      onClick={
                        (e) => {
                          e.preventDefault();
                          var updated = Object.assign({}, confirm, {
                            add_on_seating_chart_id: seatingChart.id
                          });

                          autoSaveConfirm(csrfToken, team, updated, true)
                            .then(() => {
                              createDefaultAddOns(csrfToken, team, updated, seatingChart, false);
                            })
                            .then(() => {
                              updateConfirm(updated);
                            });
                        }
                      }
                      style={{
                        "borderColor": "#f3f3f3",
                        "borderWidth": "1px"
                      }}>
                    <div className="card-body"
                        style={{"padding": "18px 6px"}}>
                      <div className="form-row">
                        <div className="col-xs-auto" style={{"paddingLeft": "15px"}}>
                          <img src={seatingChart.preview_thumb_url}
                              className="img-fluid"
                              style={{
                                "width": "120px",
                                "height": "120px"
                              }} />
                        </div>
                        <div className="col">
                          <p className="text-dark"
                            style={{
                              "fontSize": "16px",
                              "marginBottom": "4px"
                            }}>
                            <strong>{seatingChart.name}</strong>
                          </p>
                          {seatingChart.tags.map((tagName, index) =>
                            <span className="badge badge-dark small mr-2 mb-2"
                                  style={{
                                    "fontWeight": "normal",
                                    "padding": "4px 11px"
                                  }}
                                  key={index}>
                              {tagName}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="row">
              <div className="col-12">
                <AddOnsTable csrfToken={csrfToken}
                                team={team}
                                currentUser={currentUser}
                                confirm={confirm}
                                addOns={seatedAddOns.filter((ao) => !ao.seating_chart_event_id)}
                                fetchNewAddOn={fetchNewAddOn}
                                fetchAddOnToEdit={fetchAddOnToEdit}
                                deleteAddOn={deleteAddOn}
                                updateAddOn={updateAddOn}
                                autoSaveConfirm={autoSaveConfirm}
                                createDefaultAddOns={createDefaultAddOns}
                                updateConfirm={updateConfirm}
                                canChangeAddOnSeatingChart={canChangeAddOnSeatingChart(confirm, isLoadingDefaultAddOns)}
                                setDuplicatingAddOnId={setDuplicatingAddOnId}
                                isSharingInventory={isSharingInventory}
                                addOnDragged={addOnDragged} />
              </div>
            </div>
          )}
        </>
      ) : null}
      {seatingChartEvents.map((seatingChartEvent, index) => (
        <div className="row" key={index}>
          <div className="col-12">
            <hr style={{
                  "borderTop": "1px solid #979797",
                  "marginTop": "20px",
                  "marginBottom": "20px"
                }} />
          </div>
          <div className="col-12 col-md">
            <p className="mb-0"
                style={{"fontSize": "16px"}}>
              <strong>{seatingChartEvent.name}</strong>
            </p>
            <p className="text-muted small mb-1">
              This seating chart can only be used with the festival widget.
            </p>
          </div>
          <div className="col-12">
            <AddOnsTable csrfToken={csrfToken}
                            team={team}
                            currentUser={currentUser}
                            confirm={confirm}
                            addOns={seatedAddOns.filter((ao) => ao.seating_chart_event_id === seatingChartEvent.id)}
                            fetchNewAddOn={fetchNewAddOn}
                            fetchAddOnToEdit={fetchAddOnToEdit}
                            deleteAddOn={deleteAddOn}
                            updateAddOn={updateAddOn}
                            autoSaveConfirm={autoSaveConfirm}
                            createDefaultAddOns={createDefaultAddOns}
                            updateConfirm={updateConfirm}
                            canChangeAddOnSeatingChart={canChangeAddOnSeatingChart(confirm, isLoadingDefaultAddOns)}
                            setDuplicatingAddOnId={setDuplicatingAddOnId}
                            seatingChartEvent={seatingChartEvent}
                            isSharingInventory={isSharingInventory}
                            addOnDragged={addOnDragged} />
          </div>
        </div>
      ))}
      <SlidingPane
        isOpen={Object.keys(addOnForForm).length > 0}
        className="sliding-pane-container"
        width={"525px"}
        hideHeader={true}
        onAfterOpen={
          () => {
            debouncedBuildExampleTicket(csrfToken, team, confirm, addOnForForm);
          }
        }
        onRequestClose={() => {
          addOnForFormChanged({});
        }}
      >
        <React.Fragment>
          <div className="row m-0 py-4"
              style={{"borderBottom": "2px solid #fafafa"}}>
            <div className="col pl-4">
              <h3 className="mb-0"
                  style={{"fontSize": "20px"}}>
                <strong>Create Add-on</strong>
              </h3>
            </div>
            <div className="col-xs-auto"
                style={{"paddingRight": "39px"}}>
              <a href="#"
                className="text-muted"
                style={{"fontSize": "22px"}}
                onClick={
                  (e) => {
                    e.preventDefault();
                    addOnForFormChanged({});
                  }
                }>
                <i className="fal fa-times"></i>
              </a>
            </div>
          </div>
          <form onSubmit={
                  (e) => {
                    e.preventDefault();

                    if(!canSubmitForm(addOnForForm)){
                      return false;
                    }

                    if(addOnForForm.id){
                      updateAddOn(csrfToken, team, addOnForForm);
                    } else {
                      createAddOn(csrfToken, team, confirm, addOnForForm);
                    }
                  }
                }>
            <div className="row m-0">
              <div className="col-12 pt-3">
                <div className="form-group">
                  <div className="custom-control custom-checkbox custom-checkbox-table">
                    <input type="checkbox"
                          className="custom-control-input"
                          style={{"border": "1px solid #e6e6e6"}}
                          checked={addOnForForm.require_ticket_type || false}
                          onChange={
                            (e) => {
                              var updated = Object.assign({}, addOnForForm, {
                                require_ticket_type: e.target.checked,
                                ticket_type_ids: []
                              });

                              addOnForFormChanged(updated);
                            }
                          }
                          id="add-on-require-ticket-type" />
                    <label className="custom-control-label"
                          style={{"fontSize": "16px"}}
                          htmlFor="add-on-require-ticket-type">
                      This item is only available with purchase of a ticket
                    </label>
                  </div>
                </div>
                {addOnForForm.require_ticket_type ? (
                  <div className="form-group">
                    <label className="text-muted mb-1"
                          htmlFor="add-on-ticket-type-ids"
                          style={{"fontSize": "14px"}}>
                      Required Ticket(s)
                    </label>
                    <Select styles={selectStyles}
                            id={"add-on-ticket-type-ids"}
                            placeholder={"Select"}
                            isMulti={true}
                            options={
                              ticketTypes
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((tt) => ({value: tt.id, label: tt.name}))
                            }
                            value={
                              ticketTypes
                                .map((tt) => ({value: tt.id, label: tt.name}))
                                .filter((tt) => addOnForForm.ticket_type_ids.includes(tt.value))
                            }
                            onChange={
                              (selected) => {
                                var ticketTypeIds = (selected || []).map((tt) => tt.value);
                                var updated = Object.assign({}, addOnForForm, {ticket_type_ids: ticketTypeIds});

                                addOnForFormChanged(updated);
                              }
                            } />
                  </div>
                ) : null}
                <div className="form-group">
                  <label className="text-muted mb-1"
                        htmlFor="promo-code-name"
                        style={{"fontSize": "14px"}}>
                    Name
                    <span className="text-danger">&nbsp;*</span>
                  </label>
                  <input type="text"
                        className="form-control form-control-slim"
                        id="ticket-type-name"
                        value={addOnForForm.name || ""}
                        onChange={
                          (e) => {
                            var updated = Object.assign({}, addOnForForm, {name: e.target.value});
                            addOnForFormChanged(updated);
                          }
                        } />
                </div>
                <div className="form-group">
                  <label className="text-muted mb-1"
                        htmlFor="add-on-available"
                        style={{"fontSize": "14px"}}>
                    Total Quantity
                  </label>
                  <MaskedInput className="form-control form-control-slim"
                              mask={createNumberMask({
                                prefix: "",
                                decimalLimit: 0
                              })}
                              id="add-on-available"
                              disabled={addOnIsSeated(addOnForForm)}
                              onChange={
                                (e) => {
                                  var updated = Object.assign({}, addOnForForm, {available: e.target.value});
                                  addOnForFormChanged(updated);
                                }
                              }
                              value={addOnForForm.available || ""} />
                </div>
                <div className="form-group">
                  <label className="text-muted mb-1"
                        htmlFor="add-on-price"
                        style={{"fontSize": "14px"}}>
                    Price
                  </label>
                  <CurrencyInput className="form-control form-control-slim"
                                maskOptions={{
                                  prefix: "",
                                  integerLimit: null
                                }}
                                value={addOnForForm.price_two_decimals || ""}
                                onChange={
                                  (e) => {
                                    var updated = Object.assign({}, addOnForForm, {price_two_decimals: e.target.value});

                                    debouncedBuildExampleTicket(csrfToken, team, confirm, updated);
                                    addOnForFormChanged(updated);
                                  }
                                }
                                id="add-on-price" />
                  {Object.keys(exampleTicket).length > 0 ? (
                    <p className="mb-0 small text-muted">
                      Buyer total: {formatter.format(parseFloat(exampleTicket.final_cost))}
                    </p>
                  ) : null}
                </div>
                <div className="form-group">
                  <div className="custom-control custom-checkbox custom-checkbox-table">
                    <input type="checkbox"
                            className="custom-control-input"
                            onChange={
                              (e) => {
                                var updated = Object.assign({}, addOnForForm, {
                                  absorb_fees: e.target.checked
                                });

                                debouncedBuildExampleTicket(csrfToken, team, confirm, updated);
                                addOnForFormChanged(updated);
                              }
                            }
                            checked={addOnForForm.absorb_fees || false}
                            id="ticket-type-absorb-fees" />
                    <label className="custom-control-label"
                            style={{"fontSize": "14px"}}
                            htmlFor="ticket-type-absorb-fees">
                      Absorb Fees: Ticketing fees are deducted from your ticket revenue
                    </label>
                  </div>
                </div>
                {!addOnIsSeated(addOnForForm) || isSharingInventory(addOns, addOnForForm) ? (
                  <div className="form-group">
                    <label className="text-muted mb-1"
                          htmlFor="add-on-description"
                          style={{"fontSize": "14px"}}>
                      Description
                    </label>
                    <DebounceInput className="form-control form-control-slim small"
                                  element="textarea"
                                  id="add-on-description"
                                  placeholder="Tell attendees more about this add-on..."
                                  style={{"resize": "none"}}
                                  value={addOnForForm.description || ""}
                                  onChange={
                                    (e) => {
                                      var updated = Object.assign({}, addOnForForm, {description: e.target.value});
                                      addOnForFormChanged(updated);
                                    }
                                  }
                                  rows="3" />
                  </div>
                ) : null}
                <hr className="mb-3 mt-4"
                    style={{"borderTop": "1px solid #e6e6e6"}} />
                <div className="form-group">
                  <label className="text-muted mb-1"
                        htmlFor="add-on-confirmation-details"
                        style={{"fontSize": "14px"}}>
                    Details for confirmation email
                  </label>
                  <DebounceInput className="form-control form-control-slim small"
                                element="textarea"
                                id="add-on-confirmation-details"
                                placeholder="Tell attendees more about this add-on..."
                                style={{"resize": "none"}}
                                value={addOnForForm.confirmation_details || ""}
                                onChange={
                                  (e) => {
                                    var updated = Object.assign({}, addOnForForm, {confirmation_details: e.target.value});
                                    addOnForFormChanged(updated);
                                  }
                                }
                                rows="3" />
                </div>
                <div className="form-group">
                  <p>
                    <strong style={{"fontSize": "16px"}}>Add-ons Per Order</strong>
                  </p>
                </div>
                <div className="form-row">
                  <div className="col">
                    <div className="form-group">
                      <label className="text-muted mb-1"
                            htmlFor="add-on-minimum-per-order"
                            style={{"fontSize": "14px"}}>
                        Minimum
                      </label>
                      <MaskedInput className={"form-control form-control-slim " + (minPerOrderMoreThanMax(addOnForForm) ? "highlight" : "")}
                                  mask={createNumberMask({
                                    prefix: "",
                                    decimalLimit: 0
                                  })}
                                  id="add-on-minimum-per-order"
                                  disabled={addOnIsSeated(addOnForForm)}
                                  onChange={
                                    (e) => {
                                      var updated = Object.assign({}, addOnForForm, {minimum_per_order: e.target.value});
                                      addOnForFormChanged(updated);
                                    }
                                  }
                                  value={addOnForForm.minimum_per_order || ""} />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label className="text-muted mb-1"
                            htmlFor="add-on-maximum-per-order"
                            style={{"fontSize": "14px"}}>
                        Maximum
                      </label>
                      <MaskedInput className={"form-control form-control-slim " + (minPerOrderMoreThanMax(addOnForForm) ? "highlight" : "")}
                                  mask={createNumberMask({
                                    prefix: "",
                                    decimalLimit: 0
                                  })}
                                  id="add-on-maximum-per-order"
                                  onChange={
                                    (e) => {
                                      var updated = Object.assign({}, addOnForForm, {maximum_per_order: e.target.value});
                                      addOnForFormChanged(updated);
                                    }
                                  }
                                  value={addOnForForm.maximum_per_order || ""} />
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="ticket-type-availability"
                            className="text-muted mb-1"
                            style={{"fontSize": "14px"}}>
                        When are add-ons available?
                      </label>
                      <select className="form-control form-control-slim"
                              value={addOnForForm.availability || ""}
                              onChange={
                                (e) => {
                                  var availability = e.target.value;
                                  var params = {availability: availability};

                                  var updated = Object.assign({}, addOnForForm, params);
                                  addOnForFormChanged(updated);
                                }
                              }
                              id="add-on-availability">
                        <option value="Based on show details">
                          Based on show details
                        </option>
                        <option value="Date & Time">
                          Date & Time
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                {addOnForForm.availability === "Date & Time" ? (
                <React.Fragment>
                  <div className="form-row">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="ticket-type-start-date"
                              className="text-muted mb-1"
                              style={{"fontSize": "14px"}}>
                          Start Date
                          <span className="text-danger">&nbsp;*</span>
                        </label>
                        <Flatpickr
                          options={{
                            dateFormat: "M j, Y at h:iK",
                            enableTime: true,
                          }}
                          placeholder={"Select date"}
                          className={"form-control ticketing-date-picker form-control-flatpickr"}
                          value={addOnForForm.start_time}
                          onChange={dates => {
                            var startTime = parseDateTime(dates[0]);
                            var endTime   = parseDateTime(addOnForForm.end_time);

                            var attrs = {
                              start_time: startTime
                            };

                            if(endTime && endTime < startTime){
                              endTime = "";
                              attrs = Object.assign({}, attrs, {
                                end_time: endTime
                              });
                            };

                            var updated = Object.assign({}, addOnForForm, attrs);
                            addOnForFormChanged(updated);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="ticket-type-end-date"
                              className="text-muted mb-1"
                              style={{"fontSize": "14px"}}>
                          End Date
                          <span className="text-danger">&nbsp;*</span>
                        </label>
                        <Flatpickr
                          options={{
                            dateFormat: "M j, Y at h:iK",
                            enableTime: true,
                            minDate: addOnForForm.start_time,
                          }}
                          disabled={!addOnForForm.start_time}
                          placeholder={"Select date"}
                          className={"form-control ticketing-date-picker form-control-flatpickr " + (!addOnForForm.start_time ? "form-control-disabled" : "")}
                          value={addOnForForm.end_time}
                          onChange={dates => {
                            var startTime = parseDateTime(addOnForForm.start_time);
                            var endTime   = parseDateTime(dates[0]);

                            var attrs = {
                              end_time: endTime
                            };

                            if(endTime && startTime && endTime < startTime){
                              endTime = "";
                              attrs = Object.assign({}, attrs, {
                                end_time: endTime
                              });
                            };

                            var updated = Object.assign({}, addOnForForm, attrs);
                            addOnForFormChanged(updated);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="form-row">
                      <div className="col-12 form-group">
                        <label htmlFor="ticket-type-start-date"
                              className="text-muted mb-1"
                              style={{"fontSize": "14px"}}>
                          Start Show Detail
                          <span className="text-danger">&nbsp;*</span>
                        </label>
                        <Select
                          options={showActivities.map(showActivity => showActivityOption(showActivity))}
                          placeholder="Select a show detail"
                          value={showActivities.filter(showActivity => {
                            return showActivity.id === addOnForForm.start_show_activity_id
                          }).map(showActivity => showActivityOption(showActivity))}
                          onChange={option => {
                            addOnForFormChanged({
                              ...addOnForForm,
                              start_show_activity_id: option.value
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-12 form-group">
                        <label htmlFor="ticket-type-end-date"
                              className="text-muted mb-1"
                              style={{"fontSize": "14px"}}>
                          End Show Detail
                          <span className="text-danger">&nbsp;*</span>
                        </label>
                        <Select
                          options={showActivities.map(showActivity => showActivityOption(showActivity))}
                          placeholder="Select a show detail"
                          value={showActivities.filter(showActivity => {
                            return showActivity.id === addOnForForm.end_show_activity_id
                          }).map(showActivity => showActivityOption(showActivity))}
                          onChange={option => {
                            addOnForFormChanged({
                              ...addOnForForm,
                              end_show_activity_id: option.value
                            });
                          }}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )}
                {manualTaxes.length > 0 ? (
                  <div className="form-row mb-3">
                    <div className="col-12">
                      <p className="mb-1">
                        <strong style={{"fontSize": "16px"}}>Apply Taxes</strong>
                      </p>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        {manualTaxes.sort((a, b) => a.name.localeCompare(b.name)).map((manualTax, index) => (
                          <div key={index} className="custom-control custom-checkbox custom-checkbox-table">
                            <input type="checkbox"
                                    className="custom-control-input"
                                    onChange={
                                      (e) => {
                                        var newTaxableItems = [...addOnForForm.taxable_items];
                                        var foundIndex = newTaxableItems
                                          .map((taxableItem) => taxableItem.manual_tax_id)
                                          .indexOf(manualTax.id);

                                        if(e.target.checked){
                                          if(foundIndex === -1){
                                            newTaxableItems.push(
                                              {
                                                manual_tax_id: manualTax.id,
                                                _destroy: false
                                              }
                                            );
                                          } else {
                                            newTaxableItems[foundIndex]._destroy = false;
                                          }
                                        } else {
                                          if(foundIndex === -1){
                                            // no op...
                                          } else {
                                            newTaxableItems[foundIndex]._destroy = true;
                                          }
                                        }

                                        var updated = Object.assign({}, addOnForForm, {
                                          taxable_items: newTaxableItems
                                        });
                                        addOnForFormChanged(updated);
                                      }
                                    }
                                    checked={
                                      manualTaxShouldBeChecked(addOnForForm, manualTax)
                                    }
                                    id={`manual-tax-${manualTax.id}`} />
                            <label className="custom-control-label"
                                    style={{"fontSize": "14px"}}
                                    htmlFor={`manual-tax-${manualTax.id}`}>
                              {manualTax.name} ({parseFloat(manualTax.rate)}%)
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="row mt-1">
                  <div className="col-12 text-right my-4">
                    <a href="#"
                      onClick={
                        (e) => {
                          e.preventDefault();
                          addOnForFormChanged({});
                        }
                      }
                      className="btn btn-outline-primary">
                      <strong>Cancel</strong>
                    </a>
                    <button type="submit"
                            disabled={!canSubmitForm(addOnForForm)}
                            className="btn btn-primary ml-3">
                      <strong>Save</strong>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </React.Fragment>
      </SlidingPane>
      <Popup
        open={isEditingAddOnsIntro}
        contentStyle={modalContentStyle}
        closeOnDocumentClick={false}
        className={"popup-modal"}
        onOpen={() => {
          var onOpenState = stateFromHTML(confirm.add_ons_intro);
          var editorState = EditorState.createWithContent(onOpenState);

          addOnsIntroEditorStateChanged(editorState);
        }}
        onClose={() => {
          isEditingAddOnsIntroChanged(false);
        }}
      >
        <div className="popup-modal-container">
          <div className="row m-0"
              style={{"borderBottom": "2px solid #fafafa"}}>
            <div className="col d-flex align-items-center"
                style={{"padding": "25px 23px 23px 23px"}}>
              <p className="mb-0 text-dark"
                style={{"fontSize": "20px"}}>
                <strong>Add-ons Intro</strong>
              </p>
            </div>
            <div className="col-xs-auto d-flex align-items-center"
                style={{"paddingRight": "15px"}}>
              <a href="#"
                onClick={
                  (e) => {
                    e.preventDefault();
                    isEditingAddOnsIntroChanged(false);
                  }
                }
                style={{
                  "fontSize": "24px",
                  "marginRight": "12px"
                }}
                className="text-muted">
                <i className="fal fa-times"></i>
              </a>
            </div>
          </div>
          <div className="row m-0">
            <div className="col"
                style={{"padding": "24px"}}>
              <form onSubmit={
                      (e) => {
                        e.preventDefault();

                        var html = stateToHTML(addOnsIntroEditorState.getCurrentContent());
                        var updated = Object.assign({}, confirm, {
                          add_ons_intro: html
                        });

                        updateConfirm(updated);
                        autoSaveConfirm(csrfToken, team, updated);
                        isEditingAddOnsIntroChanged(false);
                      }
                    }>
                <RichEditor
                  editorState={addOnsIntroEditorState}
                  onEditorStateChange={
                    (editorState) => {
                      addOnsIntroEditorStateChanged(editorState);
                    }
                  } />
                <div className="row">
                  <div className="col-12 text-right"
                      style={{"marginTop": "20px"}}>
                    <a href="#"
                      onClick={
                        (e) => {
                          e.preventDefault();
                          isEditingAddOnsIntroChanged(false);
                        }
                      }
                      className="mr-3">
                      <strong>Cancel</strong>
                    </a>
                    <button type="submit"
                            style={{"fontSize": "14px"}}
                            className="btn btn-primary">
                      <strong>Save</strong>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Popup>
      <Popup
        open={isEditingAddOnsAlertMessage}
        contentStyle={addOnsIntroModalContentStyle}
        closeOnDocumentClick={false}
        className={"popup-modal"}
        onOpen={() => {
          var onOpenState = stateFromHTML(confirm.add_ons_alert_message);
          var editorState = EditorState.createWithContent(onOpenState);

          addOnsAlertMessageEditorStateChanged(editorState);
        }}
        onClose={() => {
          isEditingAddOnsAlertMessageChanged(false);
        }}
      >
        <div className="popup-modal-container">
          <div className="row m-0"
              style={{"borderBottom": "2px solid #fafafa"}}>
            <div className="col d-flex align-items-center"
                style={{"padding": "25px 23px 23px 23px"}}>
              <p className="mb-0 text-dark"
                style={{"fontSize": "20px"}}>
                <strong>Alert Message</strong>
              </p>
            </div>
            <div className="col-xs-auto d-flex align-items-center"
                style={{"paddingRight": "15px"}}>
              <a href="#"
                onClick={
                  (e) => {
                    e.preventDefault();
                    isEditingAddOnsAlertMessageChanged(false);
                  }
                }
                style={{
                  "fontSize": "24px",
                  "marginRight": "12px"
                }}
                className="text-muted">
                <i className="fal fa-times"></i>
              </a>
            </div>
          </div>
          <div className="row m-0">
            <div className="col"
                style={{"padding": "24px"}}>
              <form onSubmit={
                      (e) => {
                        e.preventDefault();

                        var html = stateToHTML(addOnsAlertMessageEditorState.getCurrentContent());
                        var updated = Object.assign({}, confirm, {
                          add_ons_alert_message: html
                        });

                        updateConfirm(updated);
                        autoSaveConfirm(csrfToken, team, updated);
                        isEditingAddOnsAlertMessageChanged(false);
                      }
                    }>
                <RichEditor
                  editorState={addOnsAlertMessageEditorState}
                  onEditorStateChange={
                    (editorState) => {
                      addOnsAlertMessageEditorStateChanged(editorState);
                    }
                  } />
                <div className="row">
                  <div className="col-12 text-right"
                      style={{"marginTop": "20px"}}>
                    <a href="#"
                      onClick={
                        (e) => {
                          e.preventDefault();
                          isEditingAddOnsAlertMessageChanged(false);
                        }
                      }
                      className="mr-3">
                      <strong>Cancel</strong>
                    </a>
                    <button type="submit"
                            style={{"fontSize": "14px"}}
                            className="btn btn-primary">
                      <strong>Save</strong>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Popup>
      <Popup
        open={!!duplicatingAddOnId}
        contentStyle={duplicatingAddOnModalContentStyle}
        closeOnDocumentClick={true}
        className={"popup-modal"}
        onClose={() => {
          setDuplicatingAddOnId(false);
        }}
      >
        <div className="popup-modal-container">
          <div className='row'>
            <div className='col d-flex align-items-center'>
              <p className='mb-0' style={{"fontSize": "20px"}}>
                <strong>Duplicate Add-On?</strong>
              </p>
            </div>
            <div className='col-xs-auto d-flex align-items-center'
                  style={{"paddingRight": "15px"}}>
              <a href="#"
                  onClick={
                    (e) => {
                      e.preventDefault();
                      setDuplicatingAddOnId(false);
                    }
                  }
                  className='text-dark'>
                <i className="far fa-times"
                    style={{"fontSize": "24px"}}></i>
              </a>
            </div>
          </div>
          <div className='row' style={{"marginTop": "20px", "marginBottom": "15px"}}>
            <div className='col-12'>
              <p style={{"fontSize": "14px", "marginBottom": "22px"}}>
                Creating a duplicate of a seated add-on allows you to sell seats from the same inventory at different prices.
              </p>
              <p style={{"fontSize": "14px", "marginBottom": "22px"}}>
                This should be done with great care.
              </p>
              <p style={{"fontSize": "14px"}}>
                Are you sure?
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 d-flex align-items-center justify-content-end'
                  style={{"fontSize": "14px"}}>
              {!isSavingDuplicateAddOn ? (
                <a href="#"
                    onClick={
                      (e) => {
                        e.preventDefault();
                        setDuplicatingAddOnId(false);
                      }
                    }>
                  <strong>Cancel</strong>
                </a>
              ) : null}
              <a href="#"
                  onClick={
                    (e) => {
                      e.preventDefault();

                      if(!isSavingDuplicateAddOn){
                        duplicateAddOn(csrfToken, team, duplicatingAddOnId);
                      }
                    }
                  }
                  style={{"marginLeft": "20px", "fontSize": "14px"}}
                  className={`btn btn-danger ${isSavingDuplicateAddOn ? "disabled" : ""}`}>
                {isSavingDuplicateAddOn ? (
                  <React.Fragment>
                    <img src="/uploading-loading.gif"
                          className="mr-1"
                          style={{width: "16px"}} />
                    <strong>Duplicating...</strong>
                  </React.Fragment>
                ) : (
                  <strong>Yes, duplicate add-on</strong>
                )}
              </a>
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
};

AddOnsTab.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  addOns: PropTypes.array,
  addOnForForm: PropTypes.object,
  fetchNewAddOn: PropTypes.func.isRequired,
  addOnForFormChanged: PropTypes.func.isRequired,
  ticketTypes: PropTypes.array,
  createAddOn: PropTypes.func.isRequired,
  fetchAddOnToEdit: PropTypes.func.isRequired,
  deleteAddOn: PropTypes.func.isRequired,
  updateAddOn: PropTypes.func.isRequired,
  addOnDragged: PropTypes.func.isRequired,
  addOnSeatingCharts: PropTypes.array,
  autoSaveConfirm: PropTypes.func.isRequired,
  updateConfirm: PropTypes.func.isRequired,
  isEditingAddOnsIntro: PropTypes.bool,
  isEditingAddOnsIntroChanged: PropTypes.func.isRequired,
  addOnsIntroEditorState: PropTypes.object,
  addOnsIntroEditorStateChanged: PropTypes.func.isRequired,
  createDefaultAddOns: PropTypes.func.isRequired,
  isLoadingDefaultAddOns: PropTypes.bool,
  isEditingAddOnsAlertMessage: PropTypes.bool,
  isEditingAddOnsAlertMessageChanged: PropTypes.func.isRequired,
  addOnsAlertMessageEditorState: PropTypes.object,
  addOnsAlertMessageEditorStateChanged: PropTypes.func.isRequired,
  manualTaxes: PropTypes.array,
  manualTaxShouldBeChecked: PropTypes.func.isRequired,
  addOnAdded: PropTypes.func.isRequired,
  seatingChartEvents: PropTypes.array
};

export default AddOnsTab;