// Simple example of a React "smart" component

import { connect } from 'react-redux';
import ConfirmTicketing from '../components/ConfirmTicketing';
import * as actions from '../actions/confirmTicketingActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  team: state.team,
  currentUser: state.currentUser,
  confirm: state.confirm,
  activeMenuItem: state.activeMenuItem,
  venuesOptions: state.venuesOptions,
  performers: state.performers,
  pageDetails: state.pageDetails,
  imageCalendarEventAttachments: state.imageCalendarEventAttachments,
  activeTicketingMenuItem: state.activeTicketingMenuItem,
  ticketTypeForForm: state.ticketTypeForForm,
  ticketTypes: state.ticketTypes,
  mainEventImageLoading: state.mainEventImageLoading,
  rootUrl: state.rootUrl,
  addOns: state.addOns,
  addOnForForm: state.addOnForForm,
  statementDescriptorSuffix: state.statementDescriptorSuffix,
  maxDescriptorLength: state.maxDescriptorLength,
  artistBios: state.artistBios,
  pageDetailTemplates: state.pageDetailTemplates,
  pageDetailTemplate: state.pageDetailTemplate,
  exampleTicket: state.exampleTicket,
  seatingCharts: state.seatingCharts,
  isLoadingDefaultTicketTypes: state.isLoadingDefaultTicketTypes,
  addOnSeatingCharts: state.addOnSeatingCharts,
  isEditingAddOnsIntro: state.isEditingAddOnsIntro,
  addOnsIntroEditorState: state.addOnsIntroEditorState,
  isLoadingDefaultAddOns: state.isLoadingDefaultAddOns,
  isEditingAddOnsAlertMessage: state.isEditingAddOnsAlertMessage,
  addOnsAlertMessageEditorState: state.addOnsAlertMessageEditorState,
  buyers: state.buyers,
  promoters: state.promoters,
  requirePerTicketCustomChargeTypes: state.requirePerTicketCustomChargeTypes,
  showActivities: state.showActivities,
  manualTaxes: state.manualTaxes,
  warnBeforeLeaving: state.warnBeforeLeaving,
  warnBeforeLeavingOpen: state.warnBeforeLeavingOpen,
  warnBeforeLeavingCallback: state.warnBeforeLeavingCallback,
  feeRuleSets: state.feeRuleSets,
  preSaleEnabled: state.preSaleEnabled,
  checkoutCategories: state.checkoutCategories,
  seatingChartEvents: state.seatingChartEvents
});

const mapDispatchToProps = (dispatch) => {
  return {
    activeMenuItemChanged: (activeMenuItem) => dispatch(actions.activeMenuItemChanged(activeMenuItem)),
    confirmChanged: (confirm) => dispatch(actions.confirmChanged(confirm)),
    updateConfirm: (confirm) => dispatch(actions.updateConfirm(confirm)),
    autoSaveConfirm: (csrfToken, team, confirm, throwError=false) => dispatch(actions.autoSaveConfirm(dispatch, csrfToken, team, confirm, throwError)),
    deletePerformer: (csrfToken, team, performer) => dispatch(actions.deletePerformer(dispatch, csrfToken, team, performer)),
    createPerformer: (csrfToken, team, confirm, artist) => dispatch(actions.createPerformer(dispatch, csrfToken, team, confirm, artist)),
    updatePerformer: (csrfToken, team, performer) => dispatch(actions.updatePerformer(dispatch, csrfToken, team, performer)),
    createPageDetail: (csrfToken, team, confirm, pageDetail) => dispatch(actions.createPageDetail(dispatch, csrfToken, team, confirm, pageDetail)),
    deletePageDetail: (csrfToken, team, pageDetail) => dispatch(actions.deletePageDetail(dispatch, csrfToken, team, pageDetail)),
    pageDetailChanged: (pageDetail) => dispatch(actions.pageDetailChanged(pageDetail)),
    autoSavePageDetail: (csrfToken, team, pageDetail) => dispatch(actions.autoSavePageDetail(dispatch, csrfToken, team, pageDetail)),
    pageDetailDragged: (result) => dispatch(actions.pageDetailDragged(result)),
    uploadPageDetailImage: (csrfToken, team, pageDetail, file) => dispatch(actions.uploadPageDetailImage(dispatch, csrfToken, team, pageDetail, file)),
    uploadPageDetailImageUrl: (csrfToken, team, pageDetail, url) => dispatch(actions.uploadPageDetailImageUrl(dispatch, csrfToken, team, pageDetail, url)),
    uploadConfirmMainImageFile: (csrfToken, team, confirm, file) => dispatch(actions.uploadConfirmMainImageFile(dispatch, csrfToken, team, confirm, file)),
    uploadConfirmMainImageUrl: (csrfToken, team, confirm, url) => dispatch(actions.uploadConfirmMainImageUrl(dispatch, csrfToken, team, confirm, url)),
    activeTicketingMenuItemChanged: (menuItem) => dispatch(actions.activeTicketingMenuItemChanged(menuItem)),
    ticketTypeForFormChanged: (ticketType) => dispatch(actions.ticketTypeForFormChanged(ticketType)),
    fetchNewTicketType: (csrfToken, team, confirm, ticketTypeToCopy) => dispatch(actions.fetchNewTicketType(dispatch, csrfToken, team, confirm, ticketTypeToCopy)),
    deleteTicketType: (csrfToken, team, ticketType) => dispatch(actions.deleteTicketType(dispatch, csrfToken, team, ticketType)),
    fetchTicketTypeForEdit: (csrfToken, team, ticketType) => dispatch(actions.fetchTicketTypeForEdit(dispatch, csrfToken, team, ticketType)),
    createTicketType: (csrfToken, team, confirm, ticketType) => dispatch(actions.createTicketType(dispatch, csrfToken, team, confirm, ticketType)),
    updateTicketType: (csrfToken, team, ticketType) => dispatch(actions.updateTicketType(dispatch, csrfToken, team, ticketType)),
    ticketTypeDragged: (result) => dispatch(actions.ticketTypeDragged(result)),
    publishConfirm: (csrfToken, team, confirm) => dispatch(actions.publishConfirm(dispatch, csrfToken, team, confirm)),
    fetchNewAddOn: (csrfToken, team, confirm, addOnToCopy) => dispatch(actions.fetchNewAddOn(dispatch, csrfToken, team, confirm, addOnToCopy)),
    addOnForFormChanged: (addOn) => dispatch(actions.addOnForFormChanged(addOn)),
    createAddOn: (csrfToken, team, confirm, addOn) => dispatch(actions.createAddOn(dispatch, csrfToken, team, confirm, addOn)),
    fetchAddOnToEdit: (csrfToken, team, addOn) => dispatch(actions.fetchAddOnToEdit(dispatch, csrfToken, team, addOn)),
    deleteAddOn: (csrfToken, team, addOn) => dispatch(actions.deleteAddOn(dispatch, csrfToken, team, addOn)),
    updateAddOn: (csrfToken, team, addOn) => dispatch(actions.updateAddOn(dispatch, csrfToken, team, addOn)),
    addOnDragged: (result) => dispatch(actions.addOnDragged(result)),
    statementDescriptorSuffixChanged: (statementDescriptorSuffix) => dispatch(actions.statementDescriptorSuffixChanged(statementDescriptorSuffix)),
    updateStatementDescriptorSuffix: (csrfToken, team, confirm, statementDescriptorSuffix) => dispatch(actions.updateStatementDescriptorSuffix(dispatch, csrfToken, team, confirm, statementDescriptorSuffix)),
    artistBiosChanged: (artistBios) => dispatch(actions.artistBiosChanged(artistBios)),
    deletePageDetailTemplate: (csrfToken, team, pageDetailTemplate) => dispatch(actions.deletePageDetailTemplate(dispatch, csrfToken, team, pageDetailTemplate)),
    pageDetailTemplateChanged: (pageDetailTemplate) => dispatch(actions.pageDetailTemplateChanged(pageDetailTemplate)),
    createPageDetailTemplate: (csrfToken, team, pageDetailTemplate, body, onSuccess) => dispatch(actions.createPageDetailTemplate(dispatch, csrfToken, team, pageDetailTemplate, body, onSuccess)),
    createPerformerAndArtist: (csrfToken, team, confirm, artistName) => dispatch(actions.createPerformerAndArtist(dispatch, csrfToken, team, confirm, artistName)),
    performerDragged: (result) => dispatch(actions.performerDragged(result)),
    buildExampleTicket: (csrfToken, team, confirm, ticketType) => dispatch(actions.buildExampleTicket(dispatch, csrfToken, team, confirm, ticketType)),
    exampleTicketChanged: (exampleTicket) => dispatch(actions.exampleTicketChanged(exampleTicket)),
    createDefaultTicketTypes: (csrfToken, team, confirm, seatingChart, destroyAll) => dispatch(actions.createDefaultTicketTypes(dispatch, csrfToken, team, confirm, seatingChart, destroyAll)),
    isEditingAddOnsIntroChanged: (isEditingAddOnsIntro) => dispatch(actions.isEditingAddOnsIntroChanged(isEditingAddOnsIntro)),
    addOnsIntroEditorStateChanged: (addOnsIntroEditorState) => dispatch(actions.addOnsIntroEditorStateChanged(addOnsIntroEditorState)),
    createDefaultAddOns: (csrfToken, team, confirm, seatingChart, destroyAll) => dispatch(actions.createDefaultAddOns(dispatch, csrfToken, team, confirm, seatingChart, destroyAll)),
    isEditingAddOnsAlertMessageChanged: (isEditingAddOnsAlertMessage) => dispatch(actions.isEditingAddOnsAlertMessageChanged(isEditingAddOnsAlertMessage)),
    addOnsAlertMessageEditorStateChanged: (addOnsAlertMessageEditorState) => dispatch(actions.addOnsAlertMessageEditorStateChanged(addOnsAlertMessageEditorState)),
    createRequirePerTicketCustomChargeType: (csrfToken, team, confirm) => dispatch(actions.createRequirePerTicketCustomChargeType(dispatch, csrfToken, team, confirm)),
    requirePerTicketCustomChargeTypeChanged: (requirePerTicketCustomChargeType) => dispatch(actions.requirePerTicketCustomChargeTypeChanged(requirePerTicketCustomChargeType)),
    deleteRequirePerTicketCustomChargeType: (csrfToken, team, requirePerTicketCustomChargeType) => dispatch(actions.deleteRequirePerTicketCustomChargeType(dispatch, csrfToken, team, requirePerTicketCustomChargeType)),
    autoSaveRequirePerTicketCustomChargeType: (csrfToken, team, requirePerTicketCustomChargeType) => dispatch(actions.autoSaveRequirePerTicketCustomChargeType(dispatch, csrfToken, team, requirePerTicketCustomChargeType)),
    warnBeforeLeavingChanged: (warnBeforeLeaving) => dispatch(actions.warnBeforeLeavingChanged(warnBeforeLeaving)),
    warnBeforeLeavingOpenChanged: (warnBeforeLeavingOpen) => dispatch(actions.warnBeforeLeavingOpenChanged(warnBeforeLeavingOpen)),
    warnBeforeLeavingCallbackChanged: (warnBeforeLeavingCallback) => dispatch(actions.warnBeforeLeavingCallbackChanged(warnBeforeLeavingCallback)),
    addOnAdded: (addOn) => dispatch(actions.addOnAdded(addOn)),
    checkoutCategoriesChanged: (checkoutCategories) => dispatch(actions.checkoutCategoriesChanged(checkoutCategories))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmTicketing);
