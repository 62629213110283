import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Popup from "reactjs-popup";
import NooraButton from './NooraButton';
import SlidingPane from "react-sliding-pane";

import UserMenu from '../../UserMenu/components/UserMenu';

const popupContentStyle = {
  "background": "#fafafa",
  "border": "1px solid #E9EAEB",
  "borderRadius": "12px",
  "boxShadow": "0px 12px 16px -4px rgba(10, 13, 18, 0.08), 0px 4px 6px -2px rgba(10, 13, 18, 0.03), 0px 2px 2px -1px rgba(10, 13, 18, 0.04)",
  "width": "calc(100% - 30px)",
  "padding": "0px",
  "overflow": "hidden",
  "zIndex": "999"
}

const axios = require('axios').default;

const MobileMenu = ({
  csrfToken,
  currentUser,
  team,
  menuLinks,
  teamMemberships,
  settingsLink,
  helpLink,
  myTicketsLink
}) => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = (
      typeof(window) !== 'undefined' ? window : { innerWidth: 0, innerHeight: 0 }
    );

    return {
      width,
      height
    };
  }

  const [isMounted, setIsMounted] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    setWindowDimensions(getWindowDimensions());

    var handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if(windowDimensions.width > 575 && menuOpen) {
      setMenuOpen(false);
    }
  }, [windowDimensions]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <div className="row"
          style={{"background": "white"}}>
      <div className="col-12 d-flex justify-content-between align-items-center"
            style={{"borderBottom": "2px solid #E9EAEB"}}>
        <img src="/Alt@3x.png"
              className="img-fluid"
              style={{"maxWidth": "150px"}} />
        <a href="#"
            onClick={() => setMenuOpen(true)}
            style={{"fontSize": "21px"}}
            className="text-dark">
          <i className="fal fa-bars"
              aria-hidden></i>
        </a>
      </div>
      <SlidingPane
        isOpen={menuOpen}
        from="left"
        className="sliding-pane-menu-container"
        overlayClassName="sliding-pane-menu-overlay"
        hideHeader={true}
        onRequestClose={() => {
          setMenuOpen(false);
        }}
      >
        <div className="d-flex flex-column h-100">
          <img src="/Alt@3x.png"
                className="img-fluid"
                style={{
                  "marginTop": "-10px",
                  "marginBottom": "6px",
                  "maxWidth": "150px"
                }} />
          <div className="flex-grow-1 d-flex flex-column"
                style={{
                  overflowX: "hidden",
                  overflowY: "auto",
                  scrollbarWidth: "none"
                }}>
            <div className='flex-grow-1'>
              {menuLinks.map((link, index) => (
                <div key={index}
                      className='d-flex align-items-center position-relative main-menu-link'
                      style={{
                        "padding": "10px 8px",
                        "borderRadius": "4px",
                        "marginBottom": "2px",
                        "background": (link.active ? "#fafafa" : "transparent")
                      }}>
                  <a href={link.href}
                      className='stretched-link text-dark d-flex'>
                    <div className='d-flex align-items-center justify-content-center'
                          style={{"width": "17px"}}>
                      <svg className="sprite-icon"
                            style={{
                              "width": "17px",
                              "height": "17px"
                            }}>
                        <use xlinkHref={link.xlinkHref}></use>
                      </svg>
                    </div>
                    <span className="mb-0"
                        style={{
                          "marginLeft": "13px",
                          "fontSize": "16px",
                          "fontWeight": "600"
                        }}>
                      {link.toolTip}
                    </span>
                  </a>
                </div>
              ))}
            </div>
            <div className='d-flex flex-column'>
              {Object.keys(myTicketsLink).length > 0 ? (
                <div className='d-flex align-items-center position-relative main-menu-link'
                      style={{
                        "padding": "10px 8px",
                        "borderRadius": "4px",
                        "marginBottom": "2px",
                        "background": (myTicketsLink.active ? "#fafafa" : "transparent")
                      }}>
                  <a href={myTicketsLink.href}
                      className='stretched-link text-dark d-flex'>
                    <div className='d-flex align-items-center justify-content-center'
                        style={{"width": "17px"}}>
                      <svg className="sprite-icon"
                            style={{
                              "width": "17px",
                              "height": "17px"
                            }}>
                        <use xlinkHref={myTicketsLink.xlinkHref}></use>
                      </svg>
                    </div>
                    <span className="mb-0"
                          style={{
                            "marginLeft": "13px",
                            "fontSize": "16px",
                            "fontWeight": "600"
                          }}>
                      {myTicketsLink.toolTip}
                    </span>
                  </a>
                </div>
              ) : null}
              {windowDimensions.width < 576 && isMounted ? (
                <NooraButton csrfToken={csrfToken} />
              ) : null}
              {Object.keys(helpLink).length > 0 ? (
                <div className='d-flex align-items-center position-relative main-menu-link'
                      style={{
                        "padding": "10px 8px",
                        "borderRadius": "4px",
                        "marginBottom": "2px",
                        "background": (helpLink.active ? "#fafafa" : "transparent")
                      }}>
                  <a href={helpLink.href}
                      className='stretched-link text-dark d-flex'>
                    <div className='d-flex align-items-center justify-content-center'
                        style={{"width": "17px"}}>
                      <svg className="sprite-icon"
                            style={{
                              "width": "17px",
                              "height": "17px"
                            }}>
                        <use xlinkHref={helpLink.xlinkHref}></use>
                      </svg>
                    </div>
                    <span className="mb-0"
                          style={{
                            "marginLeft": "13px",
                            "fontSize": "16px",
                            "fontWeight": "600"
                          }}>
                      {helpLink.toolTip}
                    </span>
                  </a>
                </div>
              ) : null}
              {Object.keys(settingsLink).length > 0 ? (
                <div className='d-flex align-items-center position-relative main-menu-link'
                      style={{
                        "padding": "10px 8px",
                        "borderRadius": "4px",
                        "marginBottom": "2px",
                        "background": (settingsLink.active ? "#fafafa" : "transparent")
                      }}>
                  <a href={settingsLink.href}
                      className='stretched-link text-dark d-flex'>
                    <div className='d-flex align-items-center justify-content-center'
                        style={{"width": "17px"}}>
                      <svg className="sprite-icon"
                            style={{
                              "width": "17px",
                              "height": "17px"
                            }}>
                        <use xlinkHref={settingsLink.xlinkHref}></use>
                      </svg>
                    </div>
                    <span className="mb-0"
                          style={{
                            "marginLeft": "13px",
                            "fontSize": "16px",
                            "fontWeight": "600"
                          }}>
                      {settingsLink.toolTip}
                    </span>
                  </a>
                </div>
              ) : null}
            </div>
          </div>
          <Popup arrow={false}
                offsetY={6}
                offsetX={0}
                position="top left"
                contentStyle={popupContentStyle}
                onOpen={
                  (e) => {
                    e.preventDefault();
                  }
                }
                trigger={open => (
                  <div className='d-flex position-relative'
                        style={{
                          "border": "1px solid #E9EAEB",
                          "borderRadius": "8px",
                          "marginTop": "10px",
                          "padding": "11px 5px 11px 11px"
                        }}>
                    <div className='position-relative'>
                      <img src={currentUser.avatar_thumb_url}
                            className='img-fluid rounded-circle'
                            style={{"width": "40px", "height": "40px"}} />
                      <div className='rounded-circle position-absolute'
                            style={{
                              "width": "10px",
                              "height": "10px",
                              "background": "#A2DC47",
                              "border": "1.5px solid #ffffff",
                              "bottom": "0px",
                              "right": "0px"
                            }}>
                      </div>
                    </div>
                    <div className='flex-grow-1'
                          style={{"marginLeft": "10px"}}>
                      <div style={{"maxWidth": "250px"}}>
                        <p className='mb-0 text-truncate' style={{"fontWeight": "600"}}>
                          {currentUser.first_name} {currentUser.last_name}
                        </p>
                      </div>
                      <div style={{"maxWidth": "250px"}}>
                        <p className='mb-0 text-muted text-truncate' style={{"marginTop": "-3px"}}>
                          {currentUser.email}
                        </p>
                      </div>
                    </div>
                    <a href="#"
                        className='main-menu-link d-flex flex-column align-items-center text-muted align-self-start stretched-link'
                        style={{
                          "textDecoration": "none",
                          "padding": "5px 9px",
                          "borderRadius": "8px",
                          "marginTop": "-5px"
                        }}
                        onClick={
                          (e) => {
                            e.preventDefault();
                          }
                        }>
                      <i className="far fa-angle-up"
                          aria-hidden></i>
                      <i className="far fa-angle-down"
                          aria-hidden
                          style={{"marginTop": "-5px"}}></i>
                    </a>
                  </div>
                )} >
            {close => (
              <UserMenu csrfToken={csrfToken}
                        currentUser={currentUser}
                        team={team}
                        teamMemberships={teamMemberships} />
            )}
          </Popup>
        </div>
      </SlidingPane>
    </div>
  )
};

MobileMenu.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  menuLinks: PropTypes.array.isRequired,
  teamMemberships: PropTypes.array.isRequired,
  settingsLink: PropTypes.object,
  helpLink: PropTypes.object,
  myTicketsLink: PropTypes.object
};

export default MobileMenu;
