import PropTypes from 'prop-types';
import React, { useState } from 'react';

import AddOnRow from "./AddOnRow";

import Popup from "reactjs-popup";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Tippy from '@tippyjs/react';

const clearingSeatedAddOnsModalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "492px",
  "padding": "18px 16px"
}

const addOnIsSeated = (addOn) => {
  return (
    addOn
      && addOn.seating_chart_category_key
      && addOn.seating_chart_category_key.length > 0
  )
}

const totalNumberSold = (addOns) => {
  return addOns.reduce((sum, addOn) => {
    return sum + addOn.number_of_tickets_sold;
  }, 0);
}

const totalNumberAvailable = (addOns) => {
  return addOns.reduce((sum, addOn) => {
    return sum + addOn.available;
  }, 0);
}

const sortByPositionAndName = (addOns) => {
  return addOns.sort((a, b) =>{
    if (a.position !== b.position) {
      return a.position - b.position;
    } else {
      return a.name.localeCompare(b.name);
    }
  });
}

const AddOnsTable = ({
  csrfToken,
  team,
  currentUser,
  confirm,
  addOns,
  fetchNewAddOn,
  fetchAddOnToEdit,
  deleteAddOn,
  updateAddOn,
  addOnDragged,
  autoSaveConfirm,
  createDefaultAddOns,
  updateConfirm,
  canChangeAddOnSeatingChart,
  setDuplicatingAddOnId,
  isSharingInventory,
  seatingChartEvent
}) => {
  const [clearingSeatedAddOns, setClearingSeatedAddOns] = useState(false);

  return (
    <>
      <table className={"table mb-0 vertical-middle table-responsive table-border-bottom-1 no-top-border"}
            style={{tableLayout: "fixed", "fontSize": "14px"}}>
        <colgroup>
          <col span={1} style={{"width": "5%"}} />
          <col span={1} style={{"width": "60%"}} />
          <col span={1} style={{"width": "15%"}} />
          <col span={1} style={{"width": "15%"}} />
          <col span={1} style={{"width": "5%"}} />
        </colgroup>
        <DragDropContext onDragEnd={
                          (result) => {
                            if (!result.destination) {
                              return false;
                            }

                            var itemIndex = result.source.index;
                            var moveIndex = result.destination.index;
                            var updated = Object.assign({}, addOns[itemIndex], {
                              position: (moveIndex + 1)
                            });

                            addOnDragged(result);
                            updateAddOn(csrfToken, team, updated);
                          }
                        }>
          <Droppable droppableId="add-ons-droppable">
            {(provided, snapshot) => (
              <tbody {...provided.droppableProps} ref={provided.innerRef}>
                {sortByPositionAndName(addOns).map((addOn, index) =>
                  <Draggable key={"" + addOn.id} draggableId={"" + addOn.id} index={index}>
                    {(provided, snapshot) => (
                      <AddOnRow key={index}
                                csrfToken={csrfToken}
                                provided={provided}
                                snapshot={snapshot}
                                addOns={addOns}
                                addOn={addOn}
                                team={team}
                                currentUser={currentUser}
                                confirm={confirm}
                                fetchNewAddOn={fetchNewAddOn}
                                fetchAddOnToEdit={fetchAddOnToEdit}
                                addOnIsSeated={addOnIsSeated}
                                isSharingInventory={isSharingInventory}
                                setDuplicatingAddOnId={setDuplicatingAddOnId}
                                deleteAddOn={deleteAddOn} />
                    )}
                  </Draggable>
                )}
                {provided.placeholder}
                <tr style={{"borderBottom": "none"}}>
                  <td colSpan={2}>
                    {seatingChartEvent && seatingChartEvent.id ? (
                      <Tippy content={
                                <div style={{
                                      "fontSize": "14px"
                                    }}>
                                  Please contact support to clear this seating chart.
                                </div>
                              }
                              placement={'top'}
                              maxWidth={200}
                              animation={'shift-away'}
                              theme='material'>
                        <a href="#"
                            className="small disabled"
                            onClick={
                              (e) => {
                                e.preventDefault();
                              }
                            }>
                          <strong>Clear Seating Chart</strong>
                        </a>
                      </Tippy>
                    ) : addOns[0].seating_chart_category_key && addOns[0].seating_chart_category_key.length > 0 ? (
                      canChangeAddOnSeatingChart ? (
                        <a href="#"
                            className="small"
                            onClick={
                              (e) => {
                                e.preventDefault();

                                if(canChangeAddOnSeatingChart){
                                  setClearingSeatedAddOns(true);
                                }
                              }
                            }>
                          <strong>Clear Seating Chart</strong>
                        </a>
                      ) : (
                        <Tippy content={
                                  <div style={{
                                        "fontSize": "14px"
                                      }}>
                                    Cannot be cleared once sales have started.
                                  </div>
                                }
                                placement={'top'}
                                maxWidth={200}
                                animation={'shift-away'}
                                theme='material'>
                          <a href="#"
                              className="small disabled"
                              onClick={
                                (e) => {
                                  e.preventDefault();
                                }
                              }>
                            <strong>Clear Seating Chart</strong>
                          </a>
                        </Tippy>
                      )
                    ) : (
                      <span>&nbsp;</span>
                    )}
                  </td>
                  <td className="text-muted text-center">
                    {`${totalNumberSold(addOns)}/${totalNumberAvailable(addOns)}`}
                  </td>
                  <td>
                    <span>&nbsp;</span>
                  </td>
                  <td>
                    <span>&nbsp;</span>
                  </td>
                </tr>
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </table>
      <Popup
        open={clearingSeatedAddOns}
        contentStyle={clearingSeatedAddOnsModalContentStyle}
        closeOnDocumentClick={true}
        className={"popup-modal"}
        onClose={() => {
          setClearingSeatedAddOns(false);
        }}
      >
        <div className="popup-modal-container">
          <div className='row'>
            <div className='col d-flex align-items-center'>
              <p className='mb-0' style={{"fontSize": "20px"}}>
                <strong>Clear this seating chart?</strong>
              </p>
            </div>
            <div className='col-xs-auto d-flex align-items-center'
                  style={{"paddingRight": "15px"}}>
              <a href="#"
                  onClick={
                    (e) => {
                      e.preventDefault();
                      setClearingSeatedAddOns(false);
                    }
                  }
                  className='text-dark'>
                <i className="far fa-times"
                    style={{"fontSize": "24px"}}></i>
              </a>
            </div>
          </div>
          <div className='row' style={{"marginTop": "20px", "marginBottom": "60px"}}>
            <div className='col-12'>
              <p className='mb-0' style={{"fontSize": "14px"}}>
                Clearing this seating chart will remove any seated add-ons.<br /><br />Are you sure?
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 d-flex align-items-center justify-content-end'
                  style={{"fontSize": "14px"}}>
              <a href="#"
                  onClick={
                    (e) => {
                      e.preventDefault();
                      setClearingSeatedAddOns(false);
                    }
                  }>
                <strong>Cancel</strong>
              </a>
              <a href="#"
                  onClick={
                    (e) => {
                      e.preventDefault();

                      var seatingChart = {id: ""};
                      var updated = Object.assign({}, confirm, {
                        add_on_seating_chart_id: ""
                      });

                      autoSaveConfirm(csrfToken, team, updated, true)
                        .then(() => {
                          createDefaultAddOns(csrfToken, team, updated, seatingChart, true);
                        })
                        .then(() => {
                          updateConfirm(updated);
                        })
                        .catch((error) => {});
                    }
                  }
                  style={{"marginLeft": "20px"}}
                  className='btn btn-danger'>
                Yes, clear seating chart
              </a>
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
};

AddOnsTable.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  addOns: PropTypes.array,
  fetchNewAddOn: PropTypes.func.isRequired,
  fetchAddOnToEdit: PropTypes.func.isRequired,
  deleteAddOn: PropTypes.func.isRequired,
  updateAddOn: PropTypes.func.isRequired,
  addOnDragged: PropTypes.func.isRequired,
  autoSaveConfirm: PropTypes.func.isRequired,
  createDefaultAddOns: PropTypes.func.isRequired,
  updateConfirm: PropTypes.func.isRequired,
  canChangeAddOnSeatingChart: PropTypes.bool,
  setDuplicatingAddOnId: PropTypes.func.isRequired,
  isSharingInventory: PropTypes.func.isRequired,
  seatingChartEvent: PropTypes.object
};

export default AddOnsTable;
