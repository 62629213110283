import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Tippy from "@tippyjs/react";

const NooraButton = ({csrfToken}) => {
  useEffect(() => {
    Noora("initChangelog", {
      workspaceId: "65a569c2810ed6c89ed07d71",
      position: "top",
      align: "left",
      ssoToken: csrfToken,
      theme: "light"
    });
  }, []);

  return (
    <Tippy placement="right"
            content="Updates">
      <div className={'d-flex align-items-center justify-content-center align-self-center position-relative main-menu-link'}
            style={{
              "width": "40px",
              "height": "40px",
              "minHeight": "40px",
              "borderRadius": "8px",
              "marginBottom": "5px"
            }}>
        <a href={""}
            data-noora-changelog
            onClick={
              (e) => {
                e.preventDefault();
              }
            }
            className='stretched-link'>
          <svg className="sprite-icon"
                style={{
                  "width": "17px",
                  "height": "17px",
                  "marginTop": "-3px"
                }}>
            <use xlinkHref={"#updates"}></use>
          </svg>
        </a>
      </div>
    </Tippy>
  );
};

NooraButton.propTypes = {
  csrfToken: PropTypes.string.isRequired,
};

export default NooraButton;
