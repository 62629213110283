import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const axios = require('axios').default;
import { toast } from 'react-toastify';

import MaskedInput from 'react-text-mask'
import CurrencyInput from '../../CurrencyInput/components/CurrencyInput';

import CKEditorContainer from '../../CKEditor/CKEditorContainer';

const canSubmitForm = (statementDescriptorSuffix, confirm) => {
  return (
    statementDescriptorSuffix
      && statementDescriptorSuffix.length >= 3
      && statementDescriptorSuffix !== confirm.statement_descriptor_suffix
  );
};

var _ = require('lodash');
var debouncedAutoSaveRequirePerTicketCustomChargeType;

const parseFloatFromString = (value) => {
  if(typeof(value) === "number"){
    return value;
  }

  return parseFloat(value.split(",").join(""));
}

const disableCustomChargeTypeEnabled = (customChargeType) => {
  var nameIsBlank = (
    !customChargeType.name
      || customChargeType.name.trim().length === 0
  )

  var defaultAmountIsBlank = (
    !customChargeType.default_amount
      || parseFloatFromString(customChargeType.default_amount) === 0
  )

  return (
    nameIsBlank || defaultAmountIsBlank
  );
}

const updateTicketConfirmationInfo = (csrfToken, team, confirm, ticketConfirmationInfo) => {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

  return axios.patch("/teams/" + team.id + "/confirms/" + confirm.id, {
      confirm: {
        ticket_confirmation_info: ticketConfirmationInfo
      }
    })
    .then(({ data }) => {
      toast.success("Confirmation Email successfully updated", {
        position: toast.POSITION.TOP_CENTER,
        draggable: false,
        closeOnClick: false,
        autoClose: 5000,
        hideProgressBar: true
      });
    })
    .catch((error) => {
      toast.error(error.response.data.join(", "), {
        position: toast.POSITION.TOP_CENTER,
        draggable: false,
        closeOnClick: false,
        autoClose: 5000,
        hideProgressBar: true
      });
    });
};

const Settings = ({
  csrfToken,
  team,
  currentUser,
  confirm,
  statementDescriptorSuffix,
  statementDescriptorSuffixChanged,
  maxDescriptorLength,
  updateStatementDescriptorSuffix,
  requirePerTicketCustomChargeTypes,
  createRequirePerTicketCustomChargeType,
  requirePerTicketCustomChargeTypeChanged,
  deleteRequirePerTicketCustomChargeType,
  autoSaveRequirePerTicketCustomChargeType,
  updateConfirm
}) => {
  useEffect(() => {
    debouncedAutoSaveRequirePerTicketCustomChargeType = _.debounce((csrfToken, team, requirePerTicketCustomChargeType) => {
      autoSaveRequirePerTicketCustomChargeType(csrfToken, team, requirePerTicketCustomChargeType);
    }, 300);
  }, [])

  const [confirmationInfo, setConfirmationInfo] = useState(confirm.ticket_confirmation_info || "");

  return (
    <div className="row">
      <div className="col-12">
          <form onSubmit={
                  (e) => {
                    e.preventDefault();

                    if(!canSubmitForm(statementDescriptorSuffix, confirm)){
                      return false;
                    }

                    updateStatementDescriptorSuffix(csrfToken, team, confirm, statementDescriptorSuffix);
                  }
                }>
            <div className="row" style={{"marginTop": "27px"}}>
              <div className="col-12">
                <p className="mb-1 text-dark"
                   style={{"fontSize": "20px"}}>
                  <strong>Credit Card Statement Descriptor</strong>
                </p>
                <div className="row">
                  <div className="col-12">
                    <p className="m-0" style={{"fontSize": "14px"}}>
                      Statement descriptors explain charges or payments on bank statements. You can customize how your ticketed event transactions appear on your customer’s statements.
                    </p>
                  </div>
                </div>
                <div className="form-row" style={{"marginTop": "25px"}}>
                  <div className="col col-md-6">
                    <div className="input-group input-group-sm"
                         style={{
                           "fontSize": "14px"
                         }}>
                      <div className="input-group-prepend">
                        <span className="input-group-text border-0"
                              style={{
                                "color": "#0D4568",
                                "fontFamily": "Courier New",
                                "background": "#e8edf2"
                              }}>
                          <strong>OD*&nbsp;</strong>
                        </span>
                      </div>
                      <MaskedInput
                        mask={
                          () => {
                            return Array.from(Array(maxDescriptorLength).keys())
                              .map((i) => /[^<>\\'"*]+/);
                          }
                        }
                        className="form-control"
                        style={{"border": "1px solid #b3b3b3"}}
                        guide={false}
                        onBlur={() => {}}
                        value={statementDescriptorSuffix || ""}
                        onChange={
                          (e) => {
                            statementDescriptorSuffixChanged(e.target.value);
                          }
                        }
                      />
                    </div>
                  </div>
                  <div className="col-xs-auto d-flex align-items-center"
                       style={{"paddingRight": "15px"}}>
                    <p className="mb-0 text-muted" style={{"fontSize": "10px"}}>
                      {(maxDescriptorLength - statementDescriptorSuffix.length) + " characters remaining"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{"marginTop": "38px"}}>
              <div className="col-12">
                <button type="submit"
                        style={{"fontSize": "14px"}}
                        disabled={!canSubmitForm(statementDescriptorSuffix, confirm)}
                        className="btn btn-primary">
                  <strong>Save</strong>
                </button>
              </div>
            </div>
          </form>
      </div>
      <div className="col-12" style={{"marginTop": "47px"}}>
        <form onSubmit={
                (e) => {
                  e.preventDefault();

                  var updated = Object.assign({}, confirm, {
                    ticket_confirmation_info: confirmationInfo
                  });

                  updateConfirm(updated);
                  setConfirmationInfo(confirmationInfo);

                  updateTicketConfirmationInfo(csrfToken, team, confirm, confirmationInfo);
                }
              }>
          <p className="mb-1 text-dark"
              style={{"fontSize": "20px"}}>
            <strong>Event Details for Confirmation Page and Email</strong>
          </p>
          <div className="row" style={{"marginBottom": "25px"}}>
            <div className="col-12">
              <p className="m-0" style={{"fontSize": "14px"}}>
                Add important <strong>event-specific</strong> details or instructions that will be shown within the copy of the confirmation email ticket buyers receive after their purchase. Please note: Any copy added here will be displayed <strong>in addition</strong> to any copy you have setup for the venue.  You can view the confirmation email settings for the venue <a target="_blank" href={`/teams/${team.id}/ticketing_settings`}>here</a>.
              </p>
            </div>
          </div>
          <CKEditorContainer
            content={confirmationInfo}
            setContent={setConfirmationInfo}
            containerStyle={{ maxWidth: "800px" }}
          />
          <div className="row" style={{"marginTop": "38px"}}>
            <div className="col-12">
              <button type="submit"
                      style={{"fontSize": "14px"}}
                      disabled={confirmationInfo == confirm.ticket_confirmation_info}
                      className="btn btn-primary">
                <strong>Save</strong>
              </button>
            </div>
          </div>
        </form>
      </div>
      {team.charges_processing_by === "CHARGES_PROCESSING_BY_TICKET" ? (
        <div className="col-12" style={{"marginBottom": "200px"}}>
          <div className="row" style={{"marginTop": "47px"}}>
            <div className="col-12">
              <p className="mb-1 text-dark"
                style={{"fontSize": "20px"}}>
                <strong>Custom Fees & Surcharges</strong>
              </p>
              <div className="row">
                <div className="col-12">
                  <p className="m-0" style={{"fontSize": "14px"}}>
                    Add an additional fee or surcharge to all purchases for this event. This will appear as a separate line item under feeds during checkout.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row"
              style={{
                "marginTop": "25px",
                "marginBottom": "6px"
              }}>
            {requirePerTicketCustomChargeTypes.map((customChargeType, index) =>
              <div key={index}
                  className="col-12">
                <div className="form-row">
                  <div className="col">
                    <div className="form-group">
                      <label className="text-muted mb-1"
                            style={{"fontSize": "14px"}}
                            htmlFor={"require-per-ticket-" + customChargeType.id + "-name"}>
                        Label
                      </label>
                      <input type="text"
                            className="form-control form-control-lg"
                            style={{
                              "border": "1px solid #e7e7e7",
                              "fontSize": "14px"
                            }}
                            value={customChargeType.name || ""}
                            onChange={
                              (e) => {
                                var name = e.target.value;
                                var params = {name: name};

                                if(!name || name.trim().length === 0){
                                  params = Object.assign({}, params, {
                                    enabled: false
                                  });
                                }

                                var updated = Object.assign({}, customChargeType, params);
                                debouncedAutoSaveRequirePerTicketCustomChargeType(csrfToken, team, updated);
                                requirePerTicketCustomChargeTypeChanged(updated);
                              }
                            }
                            id={"require-per-ticket-" + customChargeType.id + "-name"} />
                    </div>
                  </div>
                  <div className="col"
                      style={{"paddingLeft": "10px"}}>
                    <div className="form-group">
                      <label className="text-muted mb-1"
                            style={{"fontSize": "14px"}}
                            htmlFor={"require-per-ticket-" + customChargeType.id + "-default-amount"}>
                        Amount
                      </label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text"
                                style={{
                                  "background": "white",
                                  "borderWidth": "1px",
                                  "borderColor": "#e7e7e7",
                                  "borderTopLeftRadius": "4px",
                                  "borderBottomLeftRadius": "4px",
                                  "fontSize": "14px"
                                }}>
                            <i className="far fa-dollar-sign"></i>
                          </span>
                        </div>
                        <CurrencyInput className="form-control form-control-lg text-right"
                                      maskOptions={{
                                        prefix: "",
                                        integerLimit: null
                                      }}
                                      style={{
                                        "border": "1px solid #e7e7e7",
                                        "borderLeft": "none",
                                        "fontSize": "14px"
                                      }}
                                      value={customChargeType.default_amount || ""}
                                      onChange={
                                        (e) => {
                                          var defaultAmount = e.target.value;
                                          var params = {default_amount: defaultAmount};

                                          if(!defaultAmount || parseFloatFromString(defaultAmount) === 0){
                                            params = Object.assign({}, params, {
                                              enabled: false
                                            });
                                          }

                                          var updated = Object.assign({}, customChargeType, params);
                                          debouncedAutoSaveRequirePerTicketCustomChargeType(csrfToken, team, updated);
                                          requirePerTicketCustomChargeTypeChanged(updated);
                                        }
                                      }
                                      id={"require-per-ticket-" + customChargeType.id + "-default-amount"} />
                      </div>
                    </div>
                  </div>
                  <div className="col"
                      style={{"paddingLeft": "10px"}}>
                    <div className="form-group d-flex align-items-top"
                        style={{"marginTop": "25px"}}>
                      <div className="custom-control custom-switch custom-switch-md d-inline-block"
                          style={{"marginTop": "3px"}}>
                        <input type="checkbox"
                              className="custom-control-input"
                              checked={customChargeType.enabled || false}
                              disabled={disableCustomChargeTypeEnabled(customChargeType)}
                              onChange={
                                (e) => {
                                  var updated = Object.assign({}, customChargeType, {
                                    enabled: e.target.checked
                                  });

                                  debouncedAutoSaveRequirePerTicketCustomChargeType(csrfToken, team, updated);
                                  requirePerTicketCustomChargeTypeChanged(updated);
                                }
                              }
                              id={"require-per-ticket-" + customChargeType.id + "-enabled"} />
                        <label className="custom-control-label"
                              htmlFor={"require-per-ticket-" + customChargeType.id + "-enabled"}>
                          &nbsp;
                        </label>
                      </div>
                      {customChargeType.name || customChargeType.default_amount ? (
                        <div className="d-inline-block"
                            style={{
                              "marginLeft": "-12px",
                              "marginTop": "8px"
                            }}>
                          <a href="#"
                            onClick={
                              (e) => {
                                e.preventDefault();

                                if(window.confirm("Are you sure?")){
                                  deleteRequirePerTicketCustomChargeType(csrfToken, team, customChargeType);

                                  if(requirePerTicketCustomChargeTypes.length === 1){
                                    createRequirePerTicketCustomChargeType(csrfToken, team, confirm);
                                  }
                                }
                              }
                            }
                            className="text-muted position-relative">
                            <i className="fas fa-trash"
                              style={{"fontSize": "16px"}}></i>
                          </a>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-12">
              <a href="#"
                onClick={
                  (e) => {
                    e.preventDefault();
                    createRequirePerTicketCustomChargeType(csrfToken, team, confirm);
                  }
                }>
                <i className="fal fa-plus mr-2"></i>
                Add Another
              </a>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

Settings.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  statementDescriptorSuffix: PropTypes.string,
  statementDescriptorSuffixChanged: PropTypes.func.isRequired,
  maxDescriptorLength: PropTypes.number,
  updateStatementDescriptorSuffix: PropTypes.func.isRequired,
  requirePerTicketCustomChargeTypes: PropTypes.array,
  createRequirePerTicketCustomChargeType: PropTypes.func.isRequired,
  requirePerTicketCustomChargeTypeChanged: PropTypes.func.isRequired,
  deleteRequirePerTicketCustomChargeType: PropTypes.func.isRequired,
  autoSaveRequirePerTicketCustomChargeType: PropTypes.func.isRequired,
  updateConfirm: PropTypes.func.isRequired
};

export default Settings;
