import React from 'react';
import { Provider } from 'react-redux';

import configureStore from '../store/mobileMenuStore';
import MobileMenuContainer from '../containers/MobileMenuContainer';

// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
const MobileMenuApp = (props) => (
  <Provider store={configureStore(props)}>
    <MobileMenuContainer />
  </Provider>
);

export default MobileMenuApp;
