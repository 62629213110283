import React, { useState, useEffect, useRef, useMemo } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import "./ckeditor.css";

const LICENSE_KEY = "eyJhbGciOiJFUzI1NiJ9.eyJleHAiOjE3NjU1ODM5OTksImp0aSI6ImYzNDQwMTJkLTQwYmYtNDcxZC1hNDJmLWUxZjhiZDE4ZDk4ZCIsInVzYWdlRW5kcG9pbnQiOiJodHRwczovL3Byb3h5LWV2ZW50LmNrZWRpdG9yLmNvbSIsImRpc3RyaWJ1dGlvbkNoYW5uZWwiOlsiY2xvdWQiLCJkcnVwYWwiXSwiZmVhdHVyZXMiOlsiRFJVUCIsIkJPWCJdLCJ2YyI6IjE4ZTBkYTI5In0.veuHLDWd7_Y9HJh7bcobD6f9xypv6BnHuvKoPljPjHZj-VMZ2CRf-3X4IAPSXRTpjOHqbZs05-M6rLodVpEnzA";

const CKEditorContainer = ({
  content,
  setContent,
  containerStyle,
}) => {
  const editorRef = useRef(null);
  const [isLayoutReady, setIsLayoutReady] = useState(false);
  const [CKEditorCloud, setCKEditorCloud] = useState(null);

  useEffect(() => {
    setIsLayoutReady(true);

    const script = document.createElement("script");
    script.src = "https://cdn.ckeditor.com/ckeditor5/44.1.0/ckeditor5.umd.js";
    script.async = true;
    script.onload = () => {
      setCKEditorCloud(window.CKEDITOR);
    };
    document.body.appendChild(script);

    return () => {
      setIsLayoutReady(false);
      document.body.removeChild(script);
    };
  }, []);

  const { editorConfig, ClassicEditor } = useMemo(() => {
    if (!CKEditorCloud || !isLayoutReady) {
      return {};
    }

    const {
      Alignment,
      Autoformat,
      AutoLink,
      Autosave,
      BlockQuote,
      Bold,
      Code,
      CodeBlock,
      Essentials,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      GeneralHtmlSupport,
      Heading,
      HorizontalLine,
      HtmlEmbed,
      Indent,
      IndentBlock,
      Italic,
      Link,
      List,
      ListProperties,
      MediaEmbed,
      Paragraph,
      ShowBlocks,
      SourceEditing,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      TextTransformation,
      TodoList,
      Underline,
    } = CKEditorCloud;

    return {
      ClassicEditor: CKEditorCloud.ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "sourceEditing",
            "showBlocks",
            "|",
            "heading",
            "|",
            "fontSize",
            "fontFamily",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "underline",
            "code",
            "|",
            "horizontalLine",
            "link",
            "mediaEmbed",
            "insertTable",
            "blockQuote",
            "codeBlock",
            "htmlEmbed",
            "|",
            "alignment",
            "|",
            "bulletedList",
            "numberedList",
            "todoList",
            "outdent",
            "indent"
          ],
          shouldNotGroupWhenFull: false
        },
        plugins: [
          Alignment,
          Autoformat,
          AutoLink,
          Autosave,
          BlockQuote,
          Bold,
          Code,
          CodeBlock,
          Essentials,
          FontBackgroundColor,
          FontColor,
          FontFamily,
          FontSize,
          GeneralHtmlSupport,
          Heading,
          HorizontalLine,
          HtmlEmbed,
          Indent,
          IndentBlock,
          Italic,
          Link,
          List,
          ListProperties,
          MediaEmbed,
          Paragraph,
          ShowBlocks,
          SourceEditing,
          Table,
          TableCaption,
          TableCellProperties,
          TableColumnResize,
          TableProperties,
          TableToolbar,
          TextTransformation,
          TodoList,
          Underline
        ],
        fontFamily: {
          supportAllValues: true
        },
        fontSize: {
          options: [10, 12, 14, "default", 18, 20, 22],
          supportAllValues: true
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph"
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1"
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2"
            },
            {
              model: "heading3",
              view: "h3",
              title: "Heading 3",
              class: "ck-heading_heading3"
            },
            {
              model: "heading4",
              view: "h4",
              title: "Heading 4",
              class: "ck-heading_heading4"
            },
            {
              model: "heading5",
              view: "h5",
              title: "Heading 5",
              class: "ck-heading_heading5"
            },
            {
              model: "heading6",
              view: "h6",
              title: "Heading 6",
              class: "ck-heading_heading6"
            }
          ]
        },
        htmlSupport: {
          allow: [
            {
              name: /^.*$/,
              styles: true,
              attributes: true,
              classes: true
            }
          ]
        },
        licenseKey: LICENSE_KEY,
        link: {
          addTargetToExternalLinks: true,
          defaultProtocol: "https://",
          decorators: {
            toggleDownloadable: {
              mode: "manual",
              label: "Downloadable",
              attributes: {
                download: "file"
              }
            }
          }
        },
        list: {
          properties: {
            styles: true,
            startIndex: true,
            reversed: true
          }
        },
        placeholder: "Type or paste your content here.",
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableProperties", "tableCellProperties"]
        }
      }
    };
  }, [CKEditorCloud, isLayoutReady]);

  useEffect(() => {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = "https://cdn.ckeditor.com/ckeditor5/44.0.0/ckeditor5.css";
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  return (
    <div style={containerStyle} ref={editorRef}>
      {ClassicEditor && editorConfig ? (
        <CKEditor
          editor={ClassicEditor}
          config={editorConfig}
          data={content}
          onChange={(_event, editor) => {
            const data = editor.getData();
            setContent(data);
          }}
        />
      ) : null}
    </div>
  );
};

export default CKEditorContainer;
