/* eslint-disable import/prefer-default-export */

import {
  CONFIRM_CHANGED,
  TYPED_SEARCH_TERM_CHANGED,
  SEARCHED_SEARCH_TERM_CHANGED,
  DATA_TABLE_EL_CHANGED,
  ORDER_CHANGED,
  WIDGET_TAB_CHANGED,
  REFUND_CHANGED,
  SUBMITTING_REFUND_CHANGED,
  SLIDING_PANE_STATE_CHANGED,
  RESEND_EMAIL_CHANGED,
  SENDING_CONFIRMATION_EMAIL_CHANGED,
  FAKE_SALES_DATA_CHANGED,
  TICKET_BUYERS_DATA_CHANGED
} from '../constants/confirmDashboardConstants';

const axios = require('axios').default;
import { toast } from 'react-toastify';

export const startSearchTermUpdate = (dispatch, text) => {
  dispatch(typedSearchTermChanged(text));

  const thunk = dispatch => {
    dispatch(searchedSearchTermChanged(text));
  };

  thunk.meta = {
    debounce: {
      time: 200,
      key: 'perform-search'
    }
  };

  return thunk;
};

export const typedSearchTermChanged = (typedSearchTerm) => ({
  type: TYPED_SEARCH_TERM_CHANGED,
  typedSearchTerm
});

export const searchedSearchTermChanged = (searchedSearchTerm) => ({
  type: SEARCHED_SEARCH_TERM_CHANGED,
  searchedSearchTerm
});

export const dataTableElChanged = (el) => ({
  type: DATA_TABLE_EL_CHANGED,
  el
});

export const orderChanged = (order) => ({
  type: ORDER_CHANGED,
  order
});

export const sendOrderReceipt = (dispatch, csrfToken, team, order, resendEmail) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(sendingConfirmationEmailChanged(true));

    return axios.post("/teams/" + team.id + "/orders/" + order.id + "/send_receipt", {
        email: resendEmail
      })
      .then(({ data }) => {
        dispatch(orderChanged({}));

        toast.success("Email has been sent", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .then(function () {
        dispatch(sendingConfirmationEmailChanged(false));
      });
  };
};

export const sendOrderCheckIn = (dispatch, csrfToken, team, order) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post(`/teams/${team.id}/orders/${order.id}/check_in`)
      .then(({ data }) => {
        dispatch(orderChanged(data));

        toast.success("Order has been checked in", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const sendOrderCheckOut = (dispatch, csrfToken, team, order) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post(`/teams/${team.id}/orders/${order.id}/check_out`)
      .then(({ data }) => {
        dispatch(orderChanged(data));

        toast.success("Order has been checked out", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const widgetTabChanged = (widgetTab) => ({
  type: WIDGET_TAB_CHANGED,
  widgetTab
});

export const fetchNewRefund = (dispatch, csrfToken, team, order, ticket) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var url;
    if(ticket && ticket.barcode){
      url = ("/teams/" + team.id + "/orders/" + order.id + "/refunds/new?ticket_id=" + ticket.barcode);
    } else {
      url = ("/teams/" + team.id + "/orders/" + order.id + "/refunds/new");
    }

    return axios.get(url)
      .then(({ data }) => {
        var partialRefunds = [...data.partial_refunds].map((pf) => {
          if(pf.amount && pf.amount.length > 0){
            var parsed = parseFloat(pf.amount);

            return Object.assign({}, pf, {
              quantity: (parsed > 0 ? 1 : 0),
              amount: parsed
            });
          } else {
            return pf;
          }
        });

        var startAmount = (
          data.amount && data.amount.length > 0 ? (
            parseFloat(data.amount)
          ) : null
        );

        var refund = Object.assign({}, data, {
          amount: startAmount,
          partial_refunds: partialRefunds
        });

        dispatch(refundChanged(refund));
      });
  };
};

export const refundChanged = (refund) => ({
  type: REFUND_CHANGED,
  refund
});

export const createRefund = (dispatch, csrfToken, team, order, confirm, refund) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(submittingRefundChanged(true));

    var partialRefundsAttributes = refund.partial_refunds
      .filter((pr) => {
        return (
          pr.quantity
            && pr.quantity === 1
            && pr.ticket.eligible_for_refund
        );
      }).map((pr) => {
        return {
          ticket_id: pr.ticket_id,
          max_amount: pr.max_amount,
          amount: pr.amount,
          order_id: order.id,
          amount_refunded_to_stripe: pr.amount_refunded_to_stripe,
          amount_refunded_to_giftcard: pr.amount_refunded_to_giftcard
        };
      });

    return axios.post("/teams/" + team.id + "/orders/" + order.id + "/refunds", {
        refund: {
          calendar_event_id: confirm.id,
          reason: refund.reason,
          max_amount: refund.max_amount,
          amount: refund.amount,
          partial_refunds_attributes: partialRefundsAttributes
        }
      })
      .then(({ data }) => {
        dispatch(refundChanged(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .then(function () {
        dispatch(submittingRefundChanged(false));
      });
  };
};

export const submittingRefundChanged = (submittingRefund) => ({
  type: SUBMITTING_REFUND_CHANGED,
  submittingRefund
});

export const slidingPaneStateChanged = (slidingPaneState) => ({
  type: SLIDING_PANE_STATE_CHANGED,
  slidingPaneState
});

export const resendEmailChanged = (resendEmail) => ({
  type: RESEND_EMAIL_CHANGED,
  resendEmail
});

export const sendingConfirmationEmailChanged = (sendingConfirmationEmail) => ({
  type: SENDING_CONFIRMATION_EMAIL_CHANGED,
  sendingConfirmationEmail
});

export const fakeSalesDataChanged = (fakeSalesData) => ({
  type: FAKE_SALES_DATA_CHANGED,
  fakeSalesData
});

export const confirmChanged = (confirm) => ({
  type: CONFIRM_CHANGED,
  confirm
});

export const confirmRefund = (dispatch, csrfToken, team, order, refund) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(submittingRefundChanged(true));

    return axios.post("/teams/" + team.id + "/orders/" + order.id + "/refunds/" + refund.id + "/confirm", {
        refund: {
          financial_connection_account_id: refund.financial_connection_account_id
        }
      })
      .then(({ data }) => {
        dispatch(refundChanged(data));

        toast.success("Refund in progress...", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .then(function () {
        dispatch(submittingRefundChanged(false));
      });
  };
};

export const ticketBuyersDataChanged = (ticketBuyersData) => ({
  type: TICKET_BUYERS_DATA_CHANGED,
  ticketBuyersData
});

export const fetchTicketBuyersData = (dispatch, csrfToken, team, confirm) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.get("/teams/" + team.id + "/confirms/" + confirm.id + "/ticket_buyers_data")
      .then(({ data }) => {
        dispatch(ticketBuyersDataChanged(data));
      });
  };
};
