import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import MaskedInput from 'react-text-mask';
import SecondColumn from './SecondColumn';
import TotalsTable from './TotalsTable';

import ChooseCheckoutCategories from './ChooseCheckoutCategories';
import ChooseDonation from './ChooseDonation';
import ChooseTicketTypes from './ChooseTicketTypes';
import ChooseSeatedTicketTypes from './ChooseSeatedTicketTypes';
import ChooseAddOns from './ChooseAddOns';
import ChooseSeatedAddOns from './ChooseSeatedAddOns';
import CalendarEventInterestForm from './CalendarEventInterestForm';

import {
  CHOOSING_TICKET_TYPES,
  CHOOSING_ADD_ONS,
  CHOOSING_DONATION
} from '../constants/webOrderFormConstants';

const parseFloatFromString = (value) => {
  if(typeof(value) === "number"){
    return value;
  }

  return parseFloat(value.split(",").join(""));
}

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const canSubmitPromoCodeLookup = (promoCodeName) => {
  return (
    promoCodeName
      && promoCodeName.length > 0
  );
}

const showChooseTicketTypesSection = (confirm, ticketTypes, promoCode, visibleTicketTypes) => {
  var ticketTypesAvailableNow = visibleTicketTypes(
      confirm,
      ticketTypes,
      promoCode
    ).filter(tt => !tt.sold_out).length > 0;

  var ticketTypesAvailableLater = (
    !confirm.sold_out
      && ticketTypes.filter(tt => tt.scheduled && !tt.sold_out).length > 0
  );

  return (
    ticketTypesAvailableLater
      || ticketTypesAvailableNow
      || confirm.preselling
  );
}

const promoCodeDiscount = (promoCode) => {
  if(promoCode.percentage){
    return (promoCode.percentage + "%");
  } else {
    return formatter.format(promoCode.amount);
  }
}

const selectedObjectLabel = (object) => {
  var ownLabel;

  switch(object.objectType) {
  case "Seat":
    ownLabel = "Seat";
    break;
  case "Table":
    ownLabel = "Table";
    break;
  case "GeneralAdmissionArea":
    ownLabel = "Area";
    break;
  default:
    ownLabel = object.labels.displayedLabel;
  }

  var labelParts = [];

  if(object.labels.section){
    labelParts.push("Section " + object.labels.section);
  }

  if(object.labels.parent){
    labelParts.push("Row " + object.labels.parent);
  }

  if(object.labels.own){
    labelParts.push(ownLabel + " " + object.labels.own);
  }

  return labelParts.join(", ");
}

const selectedObjectType = (object) => {
  var objectType;

  switch(object.objectType) {
  case "GeneralAdmissionArea":
    objectType = "AddOn";
    break;
  default:
    objectType = object.objectType;
  }

  return objectType;
}

const addOnIsSeated = (addOn) => {
  return (
    addOn
      && addOn.seating_chart_category_key
      && addOn.seating_chart_category_key.length > 0
  )
}

const PickTickets = ({
  team,
  csrfToken,
  confirm,
  ticketTypes,
  promoCode,
  lookupPromoCode,
  promoCodeName,
  promoCodeNameChanged,
  promoCodeNameValid,
  promoCodeChanged,
  ticketReservation,
  ticketTypesChanged,
  buildTicketReservation,
  createTicketReservation,
  visibleTicketTypes,
  visibleAddOns,
  addOns,
  addOnsChanged,
  addOnQuantityOptions,
  ticketReservationChanged,
  userContext,
  cancelCheckout,
  postToParent,
  formatItemsForGoogleTagManager,
  seatsIOPublicKey,
  seatsIOChart,
  seatsIOChartChanged,
  seatsIOSelectedObjects,
  seatsIOSelectedObjectsChanged,
  customerioId,
  goToAddOns,
  nextStepIsChooseAddOns,
  choosingTicketTypes,
  isCreatingTicketReservation,
  ticketInsurance,
  announceListEnabled,
  waitlistEnabled,
  donationCustomChargeType,
  donationCustomChargeTypeChanged,
  debouncedBuildTicketReservation,
  feeRules,
  nextStepIsChooseDonation,
  goToDonation,
  choosingAddOns,
  showHeader,
  checkoutCategories
}) => {
  const [ticketChartSelectionValid, setTicketChartSelectionValid] = useState(true);
  const [activeCategory, setActiveCategory] = useState(checkoutCategories[0]);

  const handleSelectionChange = (isValid) => {
    setTicketChartSelectionValid(isValid);
  };

  const ticketQuantity = ticketTypes.reduce((sum, ticketType) => {
    return sum + (ticketType.quantity || 0);
  }, 0);

  const ticketsOverLimit = (
    choosingTicketTypes(ticketReservation)
      && ticketQuantity > confirm.max_tickets_per_email
  );

  const standardAddOns = addOns.filter((addOn) => !addOnIsSeated(addOn));
  const seatedAddOns = addOns.filter((addOn) => addOnIsSeated(addOn));

  const canSubmitForm = (ticketReservation, ticketTypes, addOns, choosingTicketTypes, isCreatingTicketReservation, ticketChartSelectionValid) => {
    if(isCreatingTicketReservation || !ticketChartSelectionValid){
      return false;
    }

    var hasEnteredDonation = (
      donationCustomChargeType
        && donationCustomChargeType.enteredPrice
        && donationCustomChargeType.enteredPrice.length > 0
    );

    if(hasEnteredDonation && parseFloatFromString(donationCustomChargeType.enteredPrice) <= 0.50){
      return false;
    }

    if(ticketsOverLimit){
      return false;
    }

    if(ticketReservation && ticketReservation.token){
      return (
        ticketTypes.some((tt) => tt.quantity && tt.quantity > 0)
          || addOns.some((ao) => ao.quantity && ao.quantity > 0)
          || hasEnteredDonation
      );
    }

    if(choosingTicketTypes(ticketReservation)){
      return (nextStepIsChooseAddOns || nextStepIsChooseDonation);
    } else if(choosingAddOns(ticketReservation)){
      return nextStepIsChooseDonation;
    } else {
      return false;
    }
  }

  return (
  <div>
    {showHeader ? (
      <div className="row">
        <div className="col-12">
          <p className="mb-1 mt-1 text-dark"
            style={{"fontSize": "24px"}}>
            <strong>{confirm.title}</strong>
          </p>
          {confirm.support_artist_names && confirm.support_artist_names.length > 0 ? (
            <p className="mb-1"
              style={{"fontSize": "16px", "color": "#686868"}}>
              with {confirm.support_artist_names}
            </p>
          ) : null}
          <p className="mb-1"
            style={{"color": "#686868"}}>
            {confirm.human_date_and_time_with_day_of_week}
          </p>
          {confirm.venue.has_non_country_address_info ? (
            <p className="mb-1"
              style={{"color": "#686868"}}>
              {confirm.venue.address_from_components}
            </p>
          ) : null}
        </div>
      </div>
    ) : null}
    <div className="row mt-5">
      <div className="col-12 col-md-6 col-lg-8 mb-3">
        {Object.keys(promoCode).length > 0 ? (
          <p className="mb-3">
            <i className="fas fa-check-circle mr-2"
               style={{"color": "#98c648"}}></i>
            <strong>{promoCode.name}</strong>
            <span> is applied.</span>
            <span>{" "}
              {promoCodeDiscount(promoCode)} discount{" "}
              {!promoCode.limit_to_one_ticket_per_order && !promoCode.limit_to_one_add_on_per_order ? (
                "per ticket "
              ) : promoCode.limit_to_one_ticket_per_order ? (
                "for one ticket "
              ) : promoCode.limit_to_one_add_on_per_order ? (
                "for one add on "
              ) : null}
              is available.{" "}
            </span>
            <a href="#"
               onClick={
                 (e) => {
                   e.preventDefault();
                   promoCodeChanged({});

                   var updatedTicketTypes = [...ticketTypes].map((ticketType) => {
                     if(ticketType.visibility === "Hidden"){
                       return Object.assign({}, ticketType, {quantity: 0});
                     } else {
                       return ticketType;
                     }
                   });

                   var visibleAddOnIds = visibleAddOns.map((ao) => ao.id);
                   var updatedAddOns = [...addOns].map((addOn) => {
                     if(visibleAddOnIds.includes(addOn.id)){
                       return addOn;
                     } else {
                       return Object.assign({}, addOn, {quantity: 0});
                     }
                   });

                   ticketTypesChanged(updatedTicketTypes);
                   addOnsChanged(updatedAddOns);

                   buildTicketReservation(csrfToken, confirm, updatedTicketTypes, {}, updatedAddOns, ticketReservation, userContext);
                 }
               }>
              Remove
            </a>
          </p>
        ) : (
          <form onSubmit={
                  (e) => {
                    e.preventDefault();

                    if(!canSubmitPromoCodeLookup(promoCodeName)){
                      return false;
                    }

                    lookupPromoCode(confirm, promoCodeName, ticketTypes, csrfToken, addOns, ticketReservation, userContext);
                  }
                }>
            {!promoCodeNameValid ? (
              <p className="text-danger small mb-0">
                Sorry. That code is invalid.
              </p>
            ) : null}
            <div className="input-group input-group-lg mb-3">
              <MaskedInput
                mask={
                  () => {
                    return Array.from(Array(100).keys())
                      .map((i) => /[a-zA-Z0-9@\.\+\-\_\~]/);
                  }
                }
                className={"form-control border-right-0 " + (promoCodeNameValid ? "" : "text-danger ") + (confirm.preselling ? "placeholder-text-danger " : "")}
                style={{
                  "border": promoCodeNameValid ? "1px solid #f3f3f3" : "1px solid #FF595E",
                  "fontSize": "14px"
                }}
                guide={false}
                placeholder={confirm.preselling ? "Enter Pre-Sale Code" : "Add Promo Code"}
                onBlur={() => {}}
                value={promoCodeName || ""}
                onChange={(e) => {
                  var newName = e.target.value.toUpperCase();
                  promoCodeNameChanged(newName);
                }}
              />
              <div className="input-group-append">
                <button className="btn btn-outline-secondary-dropdown border-left-0"
                        style={{
                          "border": promoCodeNameValid ? "1px solid #f3f3f3" : "1px solid #FF595E",
                          "fontSize": "14px"
                        }}
                        disabled={!canSubmitPromoCodeLookup(promoCodeName)}
                        type="submit">
                  <strong>Apply</strong>
                </button>
              </div>
            </div>
          </form>
        )}
        <form onSubmit={
                (e) => {
                  e.preventDefault();

                  if(!canSubmitForm(ticketReservation, ticketTypes, addOns, choosingTicketTypes, isCreatingTicketReservation, ticketChartSelectionValid)){
                    return false;
                  }

                  if(nextStepIsChooseAddOns){
                    goToAddOns(ticketReservation, postToParent, ticketReservationChanged);
                  } else if(nextStepIsChooseDonation){
                    goToDonation();
                  } else if(checkoutCategories.length > 0 && checkoutCategories[checkoutCategories.length - 1] !== activeCategory){
                    setActiveCategory(checkoutCategories[checkoutCategories.indexOf(activeCategory) + 1]);
                  } else {
                    postToParent("opendateOrderGoToCheckout", {});
                    createTicketReservation(csrfToken, confirm, ticketTypes, promoCode, addOns, ticketReservation, seatsIOChart, customerioId, userContext);
                  }
                }
              }>
          {checkoutCategories.length > 0 ? (
            <ChooseCheckoutCategories team={team}
                                      csrfToken={csrfToken}
                                      confirm={confirm}
                                      ticketTypes={ticketTypes}
                                      promoCode={promoCode}
                                      ticketTypesChanged={ticketTypesChanged}
                                      buildTicketReservation={buildTicketReservation}
                                      userContext={userContext}
                                      addOns={addOns}
                                      ticketReservation={ticketReservation}
                                      postToParent={postToParent}
                                      formatItemsForGoogleTagManager={formatItemsForGoogleTagManager}
                                      visibleTicketTypes={visibleTicketTypes}
                                      announceListEnabled={announceListEnabled}
                                      checkoutCategories={checkoutCategories}
                                      activeCategory={activeCategory}
                                      setActiveCategory={setActiveCategory}
                                      addOnsChanged={addOnsChanged}
                                      addOnQuantityOptions={addOnQuantityOptions}
                                      visibleAddOns={visibleAddOns}
                                      seatedAddOns={seatedAddOns}
                                      seatsIOPublicKey={seatsIOPublicKey}
                                      selectedObjectLabel={selectedObjectLabel}
                                      selectedObjectType={selectedObjectType}
                                      seatsIOChart={seatsIOChart}
                                      seatsIOChartChanged={seatsIOChartChanged}
                                      seatsIOSelectedObjects={seatsIOSelectedObjects}
                                      seatsIOSelectedObjectsChanged={seatsIOSelectedObjectsChanged}
                                      onSelectionChange={handleSelectionChange}
                                      no_orphan_seats={team.no_orphan_seats}
                                      donationCustomChargeType={donationCustomChargeType}
                                      donationCustomChargeTypeChanged={donationCustomChargeTypeChanged}
                                      debouncedBuildTicketReservation={debouncedBuildTicketReservation}
                                      feeRules={feeRules}
                                      donationTitle={confirm && confirm.donation_title ? confirm.donation_title : "Make a Donation"}
                                      donationCoverProcessingText={confirm && confirm.donation_cover_processing_text ? confirm.donation_cover_processing_text : `I'll cover processing costs so that my full donation goes to ${confirm.venue.name}`}
                                      waitlistEnabled={waitlistEnabled} />
          ) : choosingTicketTypes(ticketReservation) ? (
            confirm.seating_chart_id ? (
              <ChooseSeatedTicketTypes team={team}
                                       csrfToken={csrfToken}
                                       confirm={confirm}
                                       ticketTypes={ticketTypes}
                                       promoCode={promoCode}
                                       ticketTypesChanged={ticketTypesChanged}
                                       buildTicketReservation={buildTicketReservation}
                                       userContext={userContext}
                                       addOns={addOns}
                                       ticketReservation={ticketReservation}
                                       postToParent={postToParent}
                                       formatItemsForGoogleTagManager={formatItemsForGoogleTagManager}
                                       seatsIOPublicKey={seatsIOPublicKey}
                                       seatsIOChart={seatsIOChart}
                                       seatsIOChartChanged={seatsIOChartChanged}
                                       seatsIOSelectedObjects={seatsIOSelectedObjects}
                                       seatsIOSelectedObjectsChanged={seatsIOSelectedObjectsChanged}
                                       selectedObjectLabel={selectedObjectLabel}
                                       selectedObjectType={selectedObjectType}
                                       visibleTicketTypes={visibleTicketTypes}
                                       onSelectionChange={handleSelectionChange}
                                       announceListEnabled={announceListEnabled}
                                       waitlistEnabled={waitlistEnabled}
                                       no_orphan_seats={team.no_orphan_seats} />
            ) : (
              showChooseTicketTypesSection(confirm, ticketTypes, promoCode, visibleTicketTypes) ? (
                <ChooseTicketTypes team={team}
                                   csrfToken={csrfToken}
                                   confirm={confirm}
                                   ticketTypes={ticketTypes}
                                   promoCode={promoCode}
                                   ticketTypesChanged={ticketTypesChanged}
                                   buildTicketReservation={buildTicketReservation}
                                   userContext={userContext}
                                   addOns={addOns}
                                   ticketReservation={ticketReservation}
                                   postToParent={postToParent}
                                   formatItemsForGoogleTagManager={formatItemsForGoogleTagManager}
                                   visibleTicketTypes={visibleTicketTypes}
                                   announceListEnabled={announceListEnabled}
                                   waitlistEnabled={waitlistEnabled} />
              ) : waitlistEnabled ? (
                <CalendarEventInterestForm
                  confirm={confirm}
                  csrfToken={csrfToken}
                  interestType="Waitlist"
                />
              ) : (
                <div className="card border-0 h-100"
                     style={{"background": "#f9f9f9"}}>
                  <div className="card-body d-flex align-items-center justify-content-center">
                    <div className="text-center">
                      <p className="mb-2"
                         style={{"fontSize": "16px"}}>
                        <strong>Sold out!</strong>
                      </p>
                      <p className="mb-0">
                        This event has sold out.
                      </p>
                    </div>
                  </div>
                </div>
              )
            )
          ) : ticketReservation._screen === CHOOSING_ADD_ONS ? (
            <>
              {confirm.has_add_ons_intro && confirm.add_ons_intro_no_tags.length > 0 ? (
                <div style={{"marginTop": "30px", "marginBottom": "40px"}}
                    dangerouslySetInnerHTML={{__html: confirm.sanitized_add_ons_intro}}>
                </div>
              ) : null}
              {standardAddOns.length > 0 ? (
                <ChooseAddOns team={team}
                              csrfToken={csrfToken}
                              confirm={confirm}
                              ticketTypes={ticketTypes}
                              addOns={addOns}
                              addOnsChanged={addOnsChanged}
                              addOnQuantityOptions={addOnQuantityOptions}
                              buildTicketReservation={buildTicketReservation}
                              promoCode={promoCode}
                              ticketReservation={ticketReservation}
                              userContext={userContext}
                              postToParent={postToParent}
                              formatItemsForGoogleTagManager={formatItemsForGoogleTagManager}
                              addOnIsSeated={addOnIsSeated}
                              visibleAddOns={visibleAddOns} />
              ) : null}
              {seatedAddOns.length > 0 && confirm.add_on_seating_chart_id ? (
                <ChooseSeatedAddOns team={team}
                                    csrfToken={csrfToken}
                                    confirm={confirm}
                                    ticketTypes={ticketTypes}
                                    addOns={addOns}
                                    addOnsChanged={addOnsChanged}
                                    buildTicketReservation={buildTicketReservation}
                                    promoCode={promoCode}
                                    ticketReservation={ticketReservation}
                                    userContext={userContext}
                                    postToParent={postToParent}
                                    formatItemsForGoogleTagManager={formatItemsForGoogleTagManager}
                                    seatsIOPublicKey={seatsIOPublicKey}
                                    selectedObjectLabel={selectedObjectLabel}
                                    selectedObjectType={selectedObjectType}
                                    visibleAddOns={visibleAddOns} />
              ) : null}
              {confirm.has_add_ons_alert_message && confirm.add_ons_alert_message_no_tags.length > 0 ? (
                <div style={{
                      "marginTop": "24px",
                      "marginBottom": "12px",
                      "padding": "15px 17px",
                      "color": "#BD0006",
                      "background": "#FFDBDC",
                      "fontSize": "16px"
                    }}
                    className="alert alert-danger border-0"
                    role="alert"
                    dangerouslySetInnerHTML={{__html: confirm.sanitized_add_ons_alert_message}}>
                </div>
              ) : null}
            </>
          ) : ticketReservation._screen === CHOOSING_DONATION ? (
            <ChooseDonation team={team}
                            csrfToken={csrfToken}
                            confirm={confirm}
                            buildTicketReservation={buildTicketReservation}
                            donationCustomChargeType={donationCustomChargeType}
                            donationCustomChargeTypeChanged={donationCustomChargeTypeChanged}
                            ticketTypes={ticketTypes}
                            addOns={addOns}
                            promoCode={promoCode}
                            userContext={userContext}
                            debouncedBuildTicketReservation={debouncedBuildTicketReservation}
                            feeRules={feeRules}
                            donationTitle={confirm && confirm.donation_title ? confirm.donation_title : "Make a Donation"}
                            donationCoverProcessingText={confirm && confirm.donation_cover_processing_text ? confirm.donation_cover_processing_text : `I'll cover processing costs so that my full donation goes to ${confirm.venue.name}`}
                            ticketReservation={ticketReservation} />
          ) : null}
          <div className="row d-md-none">
            <div className="col"
                 style={{"marginBottom": "15px"}}>
              <TotalsTable ticketTypes={ticketTypes}
                           promoCode={promoCode}
                           ticketReservation={ticketReservation}
                           addOns={addOns}
                           seatsIOSelectedObjects={seatsIOSelectedObjects}
                           confirm={confirm}
                           ticketInsurance={ticketInsurance} />
            </div>
          </div>
          {ticketsOverLimit ? (
            <div className='row'>
              <div className='col-12'>
                <p  className='text-danger'>
                  {`You have exceeded the maximum number of tickets for this event. The maximum tickets for this event, established by the organizer, is ${confirm.max_tickets_per_email} tickets. Please revise your selection.`}
                </p>
              </div>
            </div>
          ) : null}
          <div className="row"
               style={{"paddingTop": "5px"}}>
            <div className="col d-flex align-items-center">
              <p className="mb-0"
                 style={{"fontSize": "14px", "color": "#686868"}}>
                Powered by
                <a href="https://www.opendate.io/"
                   target="_blank">
                  <img src="/opendate_logo.png"
                       className="ml-2"
                       style={{"width": "88px"}} />
                </a>
              </p>
            </div>
            <div className="col text-right">
              {!choosingTicketTypes(ticketReservation) && !isCreatingTicketReservation ? (
                <a href="#"
                   style={{"marginRight": "30px"}}
                   onClick={
                      (e) => {
                        e.preventDefault();
                        var requestPromises = [];

                        (typeof(seatsio) !== "undefined" && seatsio ? seatsio.charts : []).forEach((chart) => {
                          // As the chart deselects objects one-by-one, sometimes
                          // a request would get through to the server to build a
                          // new ticket reservation. This ultimately cancels the
                          // user's request to cancel since the TicketReservation
                          // is now holding an object in the cart...
                          chart.config.onObjectDeselected = (object) => {};

                          var shouldDeselect = (
                            chart
                              && chart.clearSelection
                              && (
                                chart.selectedObjects.length > 0
                                  || chart.selectedSeats.length > 0
                              )
                          );

                          if (shouldDeselect){
                            requestPromises.push(
                              chart.clearSelection()
                            );
                          }
                        })

                        Promise.all(requestPromises)
                          .then(() => {
                            postToParent("opendateOrderCanceled", {});
                            cancelCheckout();
                          });
                      }
                   }>
                  Cancel
                </a>
              ) : null}
              <button type="submit"
                      disabled={!canSubmitForm(ticketReservation, ticketTypes, addOns, choosingTicketTypes, isCreatingTicketReservation, ticketChartSelectionValid)}
                      className="btn btn-danger">
                {isCreatingTicketReservation ? (
                  <img src="/uploading-loading.gif"
                       className="mr-1"
                       style={{width: "16px"}} />
                ) : null}
                {checkoutCategories.length > 0 ? (
                  <strong>{checkoutCategories[checkoutCategories.length - 1] !== activeCategory ? "Next" : "Checkout"}</strong>
                ) : (
                  <strong>{(nextStepIsChooseAddOns || nextStepIsChooseDonation) ? "Continue" : "Checkout"}</strong>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="col-12 col-md-6 col-lg-4 mb-3 order-first order-md-last">
        <SecondColumn confirm={confirm}
                      ticketTypes={ticketTypes}
                      promoCode={promoCode}
                      addOns={addOns}
                      seatsIOSelectedObjects={seatsIOSelectedObjects}
                      ticketInsurance={ticketInsurance}
                      ticketReservation={ticketReservation} />
      </div>
    </div>
  </div>
  );
};

PickTickets.propTypes = {
  team: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  confirm: PropTypes.object.isRequired,
  ticketTypes: PropTypes.array.isRequired,
  promoCode: PropTypes.object,
  lookupPromoCode: PropTypes.func.isRequired,
  promoCodeName: PropTypes.string,
  promoCodeNameChanged: PropTypes.func.isRequired,
  promoCodeNameValid: PropTypes.bool,
  promoCodeChanged: PropTypes.func.isRequired,
  ticketReservation: PropTypes.object,
  ticketTypesChanged: PropTypes.func.isRequired,
  buildTicketReservation: PropTypes.func.isRequired,
  createTicketReservation: PropTypes.func.isRequired,
  visibleTicketTypes: PropTypes.func.isRequired,
  visibleAddOns: PropTypes.array,
  addOns: PropTypes.array,
  addOnsChanged: PropTypes.func.isRequired,
  addOnQuantityOptions: PropTypes.func.isRequired,
  ticketReservationChanged: PropTypes.func.isRequired,
  userContext: PropTypes.object,
  cancelCheckout: PropTypes.func.isRequired,
  postToParent: PropTypes.func.isRequired,
  formatItemsForGoogleTagManager: PropTypes.func.isRequired,
  seatsIOPublicKey: PropTypes.string.isRequired,
  seatsIOChart: PropTypes.object,
  seatsIOChartChanged: PropTypes.func.isRequired,
  seatsIOSelectedObjects: PropTypes.array,
  seatsIOSelectedObjectsChanged: PropTypes.func.isRequired,
  customerioId: PropTypes.string,
  goToAddOns: PropTypes.func.isRequired,
  nextStepIsChooseAddOns: PropTypes.bool,
  choosingTicketTypes: PropTypes.func.isRequired,
  isCreatingTicketReservation: PropTypes.bool,
  ticketInsurance: PropTypes.object,
  donationCustomChargeType: PropTypes.object,
  donationCustomChargeTypeChanged: PropTypes.func.isRequired,
  debouncedBuildTicketReservation: PropTypes.func,
  feeRules: PropTypes.array.isRequired,
  nextStepIsChooseDonation: PropTypes.bool,
  goToDonation: PropTypes.func.isRequired,
  choosingAddOns: PropTypes.func.isRequired,
  announceListEnabled: PropTypes.bool,
  waitlistEnabled: PropTypes.bool,
  showHeader: PropTypes.bool,
  checkoutCategories: PropTypes.array
};

export default PickTickets;
