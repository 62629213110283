import { combineReducers } from 'redux';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const menuLinks = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const teamMemberships = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const settingsLink = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const helpLink = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const myTicketsLink = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const mobileMenuReducer = combineReducers({
  csrfToken,
  currentUser,
  team,
  menuLinks,
  teamMemberships,
  settingsLink,
  helpLink,
  myTicketsLink
});

export default mobileMenuReducer;
