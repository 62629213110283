import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import TotalsTable from './TotalsTable';

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const SecondColumn = ({
  confirm,
  ticketTypes,
  promoCode,
  ticketReservation,
  addOns,
  seatsIOSelectedObjects,
  ticketInsurance
}) => (
  <div className="h-100">
    {confirm.compressed_main_event_image_url ? (
      <img src={confirm.compressed_main_event_image_url}
           className="img-fluid rounded" />
    ) : null}
    <div className="d-none d-md-block sticky-top">
      <TotalsTable ticketTypes={ticketTypes}
                   promoCode={promoCode}
                   ticketReservation={ticketReservation}
                   addOns={addOns}
                   seatsIOSelectedObjects={seatsIOSelectedObjects}
                   confirm={confirm}
                   ticketInsurance={ticketInsurance} />
    </div>
  </div>
);

SecondColumn.propTypes = {
  confirm: PropTypes.object.isRequired,
  ticketTypes: PropTypes.array.isRequired,
  promoCode: PropTypes.object,
  ticketReservation: PropTypes.object,
  addOns: PropTypes.array,
  seatsIOSelectedObjects: PropTypes.array,
  ticketInsurance: PropTypes.object
};

export default SecondColumn;
