import React from "react";

import { useBroadcastEditContext } from "./BroadcastEditContainer";

import Select from "react-select";
import AsyncSelect from "react-select/async";

const orSeparator = {
  alignItems: "center",
  display: "flex",


  ":not(:first-of-type):before": {
    content: '"or"',
    display: "block",
    marginLeft: "8px",
    height: "10px",
    width: "10px"
  }
};

const AddSegmentFilterButton = ({
  segmentFilters,
  setSegmentFilters,
}) => (
  <button
    type="button"
    className="d-flex align-items-center mr-3"
    style={{
      width: "260px",
      height: "46px",
      backgroundColor: "#fff",
      border: "2px solid #ccc",
      borderRadius: "10px",
    }}
    onClick={() => {
      setSegmentFilters(
        segmentFilters.concat({
          inclusion: true,
          segments: [],
          set: false
        })
      );
    }}
  >
    <p className="w-100 m-3 text-center" style={{ fontSize: "14px" }}>
      <strong>
        Add segment condition
      </strong>
    </p>
  </button>
);

const SegmentFilter = ({
  editable,
  index,
  segmentFilter,
  segmentFilters,
  setSegmentFilters,
  destroySegmentFilter,
}) => (
  <div key={`segment-filter-${index}`} className="d-flex align-items-top">
    {index > 0 ? (
      <div
        className="mr-2 d-flex align-items-top"
        style={{ marginTop: "12px" }}
      >
        <strong>
          AND
        </strong>
      </div>
    ) : null}
    <div
      className="mb-3 px-3 pt-2 d-flex align-items-top rounded"
      style={{
        backgroundColor: "#f2f2f2",
        border: "1px solid #ccc",
      }}
    >
      <div className="mt-1 shrink-0">
        {segmentFilter.inclusion ? "In" : "Not In"}
      </div>
      <div className="ml-2 d-flex flex-wrap align-items-center">
        {segmentFilter.segments.map((segment, i) => (
          <div
            key={`segment-${segment.id}`}
            className="mb-2 d-flex align-items-center"
          >
            {i > 0 ? (
              <div className="mx-2">
                or
              </div>
            ) : null}
            <div
              className="px-2 py-1 rounded-lg"
              style={{
                backgroundColor: "#fff",
                border: "1px solid #ccc",
              }}
            >
              {segment.label}
            </div>
          </div>
        ))}
      </div>
      {editable ? (
        <div className="mt-1 shrink-0">
          <button
            type="button"
            className="ml-2 border-0"
            onClick={() => destroySegmentFilter(segmentFilter) }
          >
            <i className="fas fa-x" />
          </button>
        </div>
      ) : null}
    </div>
  </div>
);

const EditableSegmentFilter = ({
  index,
  segmentFilter,
  segmentFilters,
  setSegmentFilters,
  createSegmentFilter,
  updateSegmentFilter,
  debouncedFetchSegments,
}) => (
  <div key={`segment-filter-${index}`} className="d-flex align-items-top">
    {index > 0 ? (
      <div
        className="mr-2 d-flex align-items-center"
        style={{ height: "38px" }}
      >
        <strong>
          AND
        </strong>
      </div>
    ) : null}
    <Select
      isDisabled={index === 0}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          width: "100px"
        })
      }}
      value={segmentFilter.inclusion ? (
        { value: true, label: "In" }
      ) : (
        { value: false, label: "Not In" }
      )}
      options={[
        { value: true, label: "In" },
        { value: false, label: "Not In" }
      ]}
      getOptionLabel={option => option.label}
      onChange={option => {
        setSegmentFilters(
          segmentFilters.map((filter, i) => {
            if (i === index) {
              return {
                ...filter,
                inclusion: option.value
              };
            }

            return filter;
          })
        );
      }}
    />
    <AsyncSelect
      className="ml-2"
      isMulti
      placeholder="Segment"
      defaultOptions
      menuPortalTarget={document.querySelector('body')}
      value={segmentFilter.segments.map(segment => ({
        value: segment.id,
        label: segment.label
      }))}
      loadOptions={(inputValue, callback) => {
        return debouncedFetchSegments(inputValue, callback);
      }}
      formatGroupLabel={data => (
        <div className="text-dark">
          <strong>{data.label}</strong>
        </div>
      )}
      getOptionLabel={option => option.label}
      onChange={options => {
        setSegmentFilters(
          segmentFilters.map((filter, i) => {
            if (i === index) {
              return {
                ...filter,
                segments: options.map(option => ({
                  id: option.value,
                  label: option.label
                })) || []
              };
            }

            return filter;
          })
        );
      }}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          minWidth: "260px",
        }),
        menu: (baseStyles, state) => ({
          ...baseStyles,
          width: "max-content",
          maxWidth: "500px"
        }),
        multiValue: (styles, { isMulti, data, index }) => {
          return index === 0 ? styles : {
            ...styles,
            ':before': {
              backgroundColor: '#fff',
              content: '" or "',
              color: 'black',
              paddingLeft: '4px',
              paddingRight: '8px',
            }
          };
        }
      }}
    />
    <button
      disabled={segmentFilter.segments.length === 0}
      type="button"
      className="btn btn-outline-primary ml-2 rounded"
      style={{ height: "38px" }}
      onClick={() => {
        const updated = segmentFilters.map((filter, i) => {
          if (i === index) {
            return {
              ...filter,
              set: true
            };
          }

          return filter;
        })

        setSegmentFilters(updated);

        if(index === 0) {
          createSegmentFilter(updated);
        } else {
          updateSegmentFilter(updated);
        }
      }}
    >
      <i className="fas fa-check" />
    </button>
    <button
      type="button"
      className="btn btn-outline-primary ml-2 rounded"
      style={{ height: "38px" }}
      onClick={() => {
        setSegmentFilters(
          segmentFilters.filter((filter, i) => (i !== index))
        );
      }}
    >
      <i className="fas fa-x" />
    </button>
  </div>
);

const SegmentFilters = ({ editable, showAddButton }) => {
  const {
    debouncedFetchSegments,
    segmentFilters,
    setSegmentFilters,
    visibleSegmentFilters,
    createSegmentFilter,
    updateSegmentFilter,
    destroySegmentFilter,
  } = useBroadcastEditContext();

  return(
    <>
      {showAddButton ? (
        <AddSegmentFilterButton
          segmentFilters={segmentFilters}
          setSegmentFilters={setSegmentFilters}
        />
      ) : null}
      <div>
        {visibleSegmentFilters.map((segmentFilter, index) => (
          segmentFilter.set ? (
            <SegmentFilter
              editable={editable}
              key={`segment-filter-${index}`}
              index={index}
              segmentFilter={segmentFilter}
              segmentFilters={segmentFilters}
              setSegmentFilters={setSegmentFilters}
              destroySegmentFilter={destroySegmentFilter}
            />
          ) : (
            <EditableSegmentFilter
              key={`segment-filter-${index}`}
              index={index}
              segmentFilter={segmentFilter}
              segmentFilters={segmentFilters}
              setSegmentFilters={setSegmentFilters}
              createSegmentFilter={createSegmentFilter}
              updateSegmentFilter={updateSegmentFilter}
              debouncedFetchSegments={debouncedFetchSegments}
            />
          )
        ))}
        {editable && visibleSegmentFilters.length !== 0 && segmentFilters.every(filter => filter.set) ? (
          <div className="d-flex align-items-top">
            <div
              className="mr-2"
              style={{ marginTop: "12px" }}
            >
              <strong>
                AND
              </strong>
            </div>
            <AddSegmentFilterButton
              segmentFilters={segmentFilters}
              setSegmentFilters={setSegmentFilters}
            />
          </div>
        ) : null}
      </div>
    </>
  );
}

export default SegmentFilters;
