import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const NooraButton = ({csrfToken}) => {
  useEffect(() => {
    Noora("initChangelog", {
      workspaceId: "65a569c2810ed6c89ed07d71",
      position: "top",
      align: "left",
      ssoToken: csrfToken,
      theme: "light"
    });
  }, []);

  return (
    <div className='d-flex align-items-center position-relative main-menu-link'
          style={{
            "padding": "10px 8px",
            "borderRadius": "4px",
            "marginBottom": "2px",
            "background": "transparent"
          }}>
      <a href="#"
          data-noora-changelog
          className='stretched-link text-dark d-flex'>
        <div className='d-flex align-items-center justify-content-center'
            style={{"width": "17px"}}>
          <svg className="sprite-icon"
                style={{
                  "width": "17px",
                  "height": "17px"
                }}>
            <use xlinkHref={"#updates"}></use>
          </svg>
        </div>
        <span className="mb-0"
              style={{
                "marginLeft": "13px",
                "fontSize": "16px",
                "fontWeight": "600"
              }}>
          Updates
        </span>
      </a>
    </div>
  );
};

NooraButton.propTypes = {
  csrfToken: PropTypes.string.isRequired,
};

export default NooraButton;

