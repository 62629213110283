import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Popup from "reactjs-popup";
import Tippy from "@tippyjs/react";
import NooraButton from './NooraButton';

import UserMenu from '../../UserMenu/components/UserMenu';

const axios = require('axios').default;

const popupContentStyle = {
  "background": "#fafafa",
  "border": "1px solid #E9EAEB",
  "borderRadius": "12px",
  "boxShadow": "0px 12px 16px -4px rgba(10, 13, 18, 0.08), 0px 4px 6px -2px rgba(10, 13, 18, 0.03), 0px 2px 2px -1px rgba(10, 13, 18, 0.04)",
  "width": "320px",
  "padding": "0px",
  "overflow": "hidden",
  "zIndex": "999"
}

const AppMenu = ({
  csrfToken,
  currentUser,
  team,
  menuLinks,
  teamMemberships,
  settingsLink,
  helpLink,
  myTicketsLink
}) => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = (
      typeof(window) !== 'undefined' ? window : { innerWidth: 0, innerHeight: 0 }
    );

    return {
      width,
      height
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [menuHeight, setMenuHeight] = useState(0);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setWindowDimensions(getWindowDimensions());

    var handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setMenuHeight(windowDimensions.height - 8);
  }, [windowDimensions]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <div>
      <div className="card text-center align-self-start"
            style={{
              "border": "1px solid #e9eaeb",
              "marginTop": "4px",
              "width": "62px",
              "height": `${menuHeight}px`,
              "position": "fixed",
              "zIndex": "999"
            }}>
        <div className="card-body d-flex flex-column align-items-center"
              style={{
                "padding": "20px 0px"
              }}>
          <img src="/Opendate-mark-512.png"
                  className="img-fluid"
                  style={{
                    "width": "36px",
                    "height": "36px",
                    "marginBottom": "15px"
                  }} />
          <div className="flex-grow-1 d-flex flex-column align-items-center"
                style={{
                  overflowX: "hidden",
                  overflowY: "auto",
                  scrollbarWidth: "none"
                }}>
            <div className='flex-grow-1'>
              {menuLinks.map((link, index) => (
                <Tippy key={index}
                        placement="right"
                        content={link.toolTip}>
                  <div className={'d-flex align-items-center justify-content-center position-relative main-menu-link' + (link.active ? ' active' : '')}
                        style={{
                          "width": "40px",
                          "height": "40px",
                          "minHeight": "40px",
                          "borderRadius": "8px",
                          "marginBottom": "5px"
                        }}>
                    <a href={link.href}
                        className='stretched-link'>
                      <svg className="sprite-icon"
                            style={{
                              "width": "17px",
                              "height": "17px",
                              "marginTop": "-3px"
                            }}>
                        <use xlinkHref={link.xlinkHref}></use>
                      </svg>
                    </a>
                  </div>
                </Tippy>
              ))}
            </div>
            <div className='d-flex flex-column align-items-center'>
              {Object.keys(myTicketsLink).length > 0 ? (
                <Tippy placement="right"
                      content={myTicketsLink.toolTip}>
                  <div className={'d-flex align-items-center justify-content-center align-self-center position-relative main-menu-link' + (myTicketsLink.active ? ' active' : '')}
                        style={{
                          "width": "40px",
                          "height": "40px",
                          "minHeight": "40px",
                          "borderRadius": "8px",
                          "marginBottom": "5px"
                        }}>
                    <a href={myTicketsLink.href}
                        className='stretched-link'>
                      <svg className="sprite-icon"
                            style={{
                              "width": "17px",
                              "height": "17px",
                              "marginTop": "-3px"
                            }}>
                        <use xlinkHref={myTicketsLink.xlinkHref}></use>
                      </svg>
                    </a>
                  </div>
                </Tippy>
              ) : null}
              {windowDimensions.width >= 576 && isMounted ? (
                <NooraButton csrfToken={csrfToken} />
              ) : null}
              {Object.keys(helpLink).length > 0 ? (
                <Tippy placement="right"
                        content={helpLink.toolTip}>
                  <div className={'d-flex align-items-center justify-content-center align-self-center position-relative main-menu-link' + (helpLink.active ? ' active' : '')}
                        style={{
                          "width": "40px",
                          "height": "40px",
                          "minHeight": "40px",
                          "borderRadius": "8px",
                          "marginBottom": "5px"
                        }}>
                    <a href={helpLink.href}
                        className='stretched-link'>
                      <svg className="sprite-icon"
                            style={{
                              "width": "17px",
                              "height": "17px",
                              "marginTop": "-3px"
                            }}>
                        <use xlinkHref={helpLink.xlinkHref}></use>
                      </svg>
                    </a>
                  </div>
                </Tippy>
              ) : null}
              {Object.keys(settingsLink).length > 0 ? (
                <Tippy placement="right"
                        content={settingsLink.toolTip}>
                  <div className={'d-flex align-items-center justify-content-center align-self-center position-relative main-menu-link' + (settingsLink.active ? ' active' : '')}
                        style={{
                          "width": "40px",
                          "height": "40px",
                          "minHeight": "40px",
                          "borderRadius": "8px",
                          "marginBottom": "5px"
                        }}>
                    <a href={settingsLink.href}
                        className='stretched-link'>
                      <svg className="sprite-icon"
                            style={{
                              "width": "17px",
                              "height": "17px",
                              "marginTop": "-3px"
                            }}>
                        <use xlinkHref={settingsLink.xlinkHref}></use>
                      </svg>
                    </a>
                  </div>
                </Tippy>
              ) : null}
            </div>
          </div>
          <div style={{"marginTop": "10px"}}>
            <Popup arrow={false}
                  offsetY={0}
                  offsetX={5}
                  position="right bottom"
                  contentStyle={popupContentStyle}
                  onOpen={
                    (e) => {
                      e.preventDefault();
                    }
                  }
                  trigger={open => (
                    <a href="#">
                      <div className='position-relative'>
                        <img src={currentUser.avatar_thumb_url}
                              className='img-fluid rounded-circle'
                              style={{"width": "40px", "height": "40px"}} />
                        <div className='rounded-circle position-absolute'
                              style={{
                                "width": "10px",
                                "height": "10px",
                                "background": "#A2DC47",
                                "border": "1.5px solid #ffffff",
                                "bottom": "0px",
                                "right": "0px"
                              }}>
                        </div>
                      </div>
                    </a>
                  )} >
              {close => (
                <UserMenu csrfToken={csrfToken}
                          currentUser={currentUser}
                          team={team}
                          teamMemberships={teamMemberships} />
              )}
            </Popup>
          </div>
        </div>
      </div>
    </div>
  )
};

AppMenu.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  menuLinks: PropTypes.array.isRequired,
  teamMemberships: PropTypes.array.isRequired,
  settingsLink: PropTypes.object,
  helpLink: PropTypes.object,
  myTicketsLink: PropTypes.object
};

export default AppMenu;
